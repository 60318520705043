import React, { FC } from 'react';
import { Notification } from '../../../common/model/dto/notification/notification';
import { OrderBy, Paginated, SortDirection } from '../../../common/types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import OvListTable from '../atoms/OvListTable';
import OvListTableRow from '../atoms/OvListTableRow';
import OvListTableHeaderField from '../atoms/OvListTableHeaderField';
import OvNotificationListRow from './OvNotificationListRow';
import { RefetchFunction } from 'axios-hooks';
import { NotificationFields } from '../../../firebase/document-field.enums';

interface OvNotificationListProps {
  notifications: Notification[];
  onSort: (orderBy: OrderBy) => void;
  orderBy: string;
  orderDir: SortDirection;
  refetch: RefetchFunction<any, Paginated<Notification>>;
  setErrorMessage: (error: string) => void;
  setIsRequestLoading: (isLoading: boolean) => void;
}

const OvNotificationList: FC<OvNotificationListProps> = ({
  notifications,
  onSort,
  orderBy,
  orderDir,
  refetch,
  setErrorMessage,
  setIsRequestLoading,
}) => {
  const { t } = useTranslation();

  const getSortDirection = (field: string): SortDirection =>
    orderBy === field ? orderDir : 'none';

  return (
    <WrapperBox>
      <OvListTable>
        <OvListTableRow>
          <OvListTableHeaderField>
            {t('notifications.fields.contentTitle')}
          </OvListTableHeaderField>
          <OvListTableHeaderField>
            {t('notifications.fields.userEmail')}
          </OvListTableHeaderField>
          <OvListTableHeaderField>
            {t('notifications.fields.category')}
          </OvListTableHeaderField>
          <OvListTableHeaderField
            sortDirection={getSortDirection(
              NotificationFields.is_approvement_pending
            )}
            sortBy={NotificationFields.is_approvement_pending}
            onSort={onSort}
          >
            {t('notifications.fields.isApprovementPending')}
          </OvListTableHeaderField>
          <OvListTableHeaderField>
            {t('notifications.fields.isApproved')}
          </OvListTableHeaderField>
          <OvListTableHeaderField
            sortDirection={getSortDirection(NotificationFields.created_at)}
            sortBy={NotificationFields.created_at}
            onSort={onSort}
          >
            {t('notifications.fields.createdAt')}
          </OvListTableHeaderField>
          <OvListTableHeaderField style={{ textAlign: 'right' }}>
            {t('common.operation')}
          </OvListTableHeaderField>
          <OvListTableHeaderField style={{ textAlign: 'right' }}>
            {t('common.action')}
          </OvListTableHeaderField>
        </OvListTableRow>

        {notifications.map((notification) => (
          <OvListTableRow key={notification.id}>
            <OvNotificationListRow
              notification={notification}
              refetch={refetch}
              setErrorMessage={setErrorMessage}
              setIsRequestLoading={setIsRequestLoading}
            />
          </OvListTableRow>
        ))}
      </OvListTable>
    </WrapperBox>
  );
};

export default OvNotificationList;

const WrapperBox = styled.div`
  padding: 1.5rem 1rem 1rem;
  overflow-x: auto;
`;
