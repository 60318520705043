import axios from 'axios';
import { Paginated } from '../common/types';
import { ADMIN_SCANS_ROUTE } from '../api/backend';
import { Scan } from '../common/model/dto/scan';
import { CreateScanRequest } from '../common/model/dto/create-scan-request';

export class ScansService {
  static ADMIN_SCAN_LIST_LIMIT = 10;

  static async loadScans(query: string) {
    const { data } = await axios.get<Paginated<Scan>>(
      `${ADMIN_SCANS_ROUTE}?${query}`
    );

    return data;
  }

  static async createScan(createScanRequest: CreateScanRequest): Promise<Scan> {
    const { data } = await axios.post<Scan>(
      `${ADMIN_SCANS_ROUTE}?prevent_processing=true`,
      createScanRequest
    );
    return data;
  }

  static async createScanAndProcess(
    createScanRequest: CreateScanRequest
  ): Promise<Scan> {
    const { data } = await axios.post<Scan>(
      `${ADMIN_SCANS_ROUTE}`,
      createScanRequest
    );
    return data;
  }

  static async findScanById(scanId: string): Promise<Scan> {
    const { data } = await axios.get<Scan>(`${ADMIN_SCANS_ROUTE}/${scanId}`);

    return data;
  }

  static async updateScan(scanId: string, updateObj: any): Promise<Scan> {
    const { data } = await axios.patch<Scan>(
      `${ADMIN_SCANS_ROUTE}/${scanId}`,
      updateObj
    );
    return data;
  }

  static async deleteScan(scanId: string) {
    return await axios.delete(`${ADMIN_SCANS_ROUTE}/${scanId}`);
  }

  static async loadAllAppVersions() {
    const { data } = await axios.get<
      {
        app_version: string;
      }[]
    >(`${ADMIN_SCANS_ROUTE}/app-versions`);

    return data;
  }

  static async loadAllScanDevices() {
    const { data } = await axios.get<
      {
        device_model: string;
      }[]
    >(`${ADMIN_SCANS_ROUTE}/devices`);

    return data;
  }
}
