import React, { FC, useEffect, useState } from 'react';
import {
  Link,
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import styled from 'styled-components';
import OvButton from '../UI/atoms/OvButton';
import { useTranslation } from 'react-i18next';
import Variables from '../../design-system/variables';
import Colours from '../../design-system/colours';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import OvLoadingIndicator from '../UI/atoms/OvLoadingIndicator';
import { last } from 'lodash';
import OvDateParser from '../UI/atoms/OvDateParser';
import { Alert, AlertTitle, Tab, Tabs } from '@mui/material';
import OvJournalEntryDetailPanel from '../UI/organisms/OvJournalEntryDetailPanel';
import {
  createJournalEntry,
  loadDailyDataEntity,
  updateJournalEntry,
} from '../../redux/thunks/daily-data.thunk';
import { clearSelectedDailyData } from '../../redux/reducers/daily-data.slice';
import { DailyData } from '../../common/model/dto/daily-data';
import OvUserScanList from '../UI/organisms/OvUserScanList';
import { loadScansBetweenDateRange } from '../../redux/thunks/scans.thunk';
import { Scan } from '../../common/model/dto/scan';
import { clearScanList } from '../../redux/reducers/scans.slice';
import OvPrettyUserName from '../UI/atoms/OvPrettyUserName';
import { UserInfo } from '../../common/model/dto/user-info';
import { getUserDetails } from '../../redux/thunks/user.thunk';
import { parseISO } from 'date-fns';
import OvBackButton from '../UI/atoms/OvBackButton';
import OvWearableInfo from '../UI/organisms/OvWearableInfo';

const DailyDataDetails: FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  let { path, url } = useRouteMatch();
  let location = useLocation();

  const { dailyDataId }: any = useParams();
  const tabs = {
    JOURNAL_ENTRY: 'journal-entry',
    SCANS: 'scans',
    WEARABLE_INFO: 'wearable-info',
  };
  const [tab, setTab] = React.useState<string>(tabs.JOURNAL_ENTRY);
  const selectedDailyData: DailyData = useAppSelector(
    (state) => state.dailyData.selectedDailyData
  );
  const scanList: Scan[] = useAppSelector((state) => state.scans.scanList);
  const isDailyDataLoading: boolean = useAppSelector(
    (state) => state.dailyData.isLoading
  );
  const isUserLoading = useAppSelector((state) => state.user.isLoading);
  const isLoading = isUserLoading || isDailyDataLoading;
  const selectedUser: UserInfo | undefined = useAppSelector(
    (state) => state.user.selectedUser
  );
  const [userDocumentId, setUserDocumentId] = useState<string | undefined>(
    undefined
  );
  const [day, setDay] = useState<string | undefined>(undefined);

  const refetch = () => {
    const [userDocumentId, day] = dailyDataId?.split('_');

    return dispatch(
      loadScansBetweenDateRange({
        dayFrom: day,
        dayTo: day,
        userDocumentId,
      })
    );
  };

  useEffect(() => {
    if (dailyDataId) {
      const [userDocumentId, day] = dailyDataId?.split('_');

      setUserDocumentId(userDocumentId);
      setDay(day);

      dispatch(
        loadDailyDataEntity({
          day,
          userDocumentId,
        })
      );

      refetch();

      if (!selectedUser) {
        dispatch(getUserDetails(userDocumentId));
      }
    }

    return function cleanup() {
      dispatch(clearSelectedDailyData());
      dispatch(clearScanList());
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dailyDataId, dispatch]);

  useEffect(() => {
    const setActiveTabFromUrl = () => {
      const pathName = last(location.pathname.split('/'));

      if (
        pathName === tabs.SCANS ||
        pathName === tabs.JOURNAL_ENTRY ||
        pathName === tabs.WEARABLE_INFO
      ) {
        setTab(pathName);
      }
    };

    setActiveTabFromUrl();
  }, [
    tabs.SCANS,
    tabs.JOURNAL_ENTRY,
    tabs.WEARABLE_INFO,
    history,
    url,
    location.pathname,
  ]);

  const handleTabChange = (event: any, newTab: string) => {
    if (newTab !== null) {
      setTab(newTab);
    }
  };

  const onSaveEditedJournalEntryField = (editObject: any) => {
    if (selectedDailyData.journal_entry_id) {
      dispatch(
        updateJournalEntry({
          id: selectedDailyData.journal_entry_id,
          updatedProperties: {
            ...editObject,
          },
        })
      );
    }
  };

  const createNewJournalEntry = () => {
    if (selectedDailyData.day) {
      dispatch(
        createJournalEntry({
          history,
          createJournalEntryRequest: {
            day: selectedDailyData.day,
            user_id: selectedUser?.id,
            user_document_id: selectedUser?.document_id,
          },
        })
      );
    }
  };

  return (
    <>
      <DetailPageWrapper>
        <Header>
          <InfoWrapper>
            <OvBackButton label={t('common.actions.back')} />
            <StyledLink to={`/users/${selectedDailyData?.user_id}`}>
              <PrettyUserName>
                <OvPrettyUserName user={selectedUser} />
              </PrettyUserName>
            </StyledLink>
            <DateInfo>
              <Separator>-</Separator>
              {selectedDailyData && (
                <OvDateParser
                  date={parseISO(selectedDailyData.day as string)}
                  format={'dddd, MMMM DD, YYYY'}
                />
              )}
            </DateInfo>
          </InfoWrapper>
          <UserActionWrapper>
            {selectedDailyData?.journal_entry_id === null && (
              <StyledOvButton onClick={createNewJournalEntry}>
                {t('dailyDataDetails.journalEntry.createJournalEntry')}
              </StyledOvButton>
            )}
          </UserActionWrapper>
        </Header>

        {selectedDailyData?.journal_entry?.is_demo_journal_entry && (
          <StyledAlert severity="warning">
            <AlertTitle>
              {t('dailyDataDetails.journalEntry.demoAlertTitle')}
            </AlertTitle>
            {t('dailyDataDetails.journalEntry.demoAlertDesc')}
          </StyledAlert>
        )}

        <TabContainer>
          <Tabs onChange={handleTabChange} value={tab}>
            <Tab
              value={tabs.JOURNAL_ENTRY}
              label={t('dailyDataDetails.tabs.journalEntry')}
              component={Link}
              to={`${url}/${tabs.JOURNAL_ENTRY}`}
              replace
              style={{
                borderBottom: '2px solid rgba(0, 0, 0, 0.38)',
                textTransform: 'none',
              }}
            />
            <Tab
              value={tabs.SCANS}
              label={t('dailyDataDetails.tabs.scans')}
              component={Link}
              to={`${url}/${tabs.SCANS}`}
              replace
              style={{
                borderBottom: '2px solid rgba(0, 0, 0, 0.38)',
                textTransform: 'none',
              }}
            />
            <Tab
              value={tabs.WEARABLE_INFO}
              label={t('dailyDataDetails.tabs.wearableInfo')}
              component={Link}
              to={`${url}/${tabs.WEARABLE_INFO}`}
              replace
              style={{
                borderBottom: '2px solid rgba(0, 0, 0, 0.38)',
                textTransform: 'none',
              }}
            />
          </Tabs>
        </TabContainer>

        <Switch>
          <Route path={`${path}/${tabs.JOURNAL_ENTRY}`}>
            <OvJournalEntryDetailPanel
              journalEntry={selectedDailyData?.journal_entry}
              onSaveEditedJournalField={onSaveEditedJournalEntryField}
            />
          </Route>
          <Route path={`${path}/${tabs.SCANS}`}>
            <OvUserScanList
              scans={scanList}
              showDetailsAction={true}
              selectedUserId={userDocumentId}
              selectedDay={day}
              showOperation={true}
              refetch={refetch}
            />
          </Route>
          <Route path={`${path}/${tabs.WEARABLE_INFO}`}>
            <OvWearableInfo spikeInfo={selectedDailyData?.spike_info} />
          </Route>
          <Route exact path={path}>
            <Redirect to={`${url}/${tabs.JOURNAL_ENTRY}`} />
          </Route>
        </Switch>
      </DetailPageWrapper>

      {isLoading && <OvLoadingIndicator position="fixed" />}
    </>
  );
};

export default DailyDataDetails;

const DetailPageWrapper = styled.div`
  margin-left: 1rem;
  box-shadow: ${Variables.boxShadow.defaultBox};
  background-color: ${Colours.WHITE};
  padding: 1.5rem;
  border-radius: ${Variables.borderRadius.SMALL};
`;

const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
`;

const UserActionWrapper = styled.div``;

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
`;

const PrettyUserName = styled.h6`
  margin: 0;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.5rem;
`;

const DateInfo = styled.span`
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.5rem;
`;

const Separator = styled.span`
  margin: 0 0.75rem;
`;

const TabContainer = styled.div`
  margin-bottom: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  && {
    .MuiTabs-indicator {
      background-color: ${Colours.OV_BASE};
    }
  }
`;

const StyledLink = styled(Link)`
  && {
    text-decoration: none;
    color: ${Colours.OV_BASE};

    &:hover {
      color: ${Colours.OV_BASE_HOVER};
    }
  }
`;

const StyledOvButton = styled(OvButton)`
  && {
    display: flex;
    align-items: center;
    text-transform: none;
    padding: 2px 10px;
    padding: 2px 0.625rem;
    border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
    margin-right: 0.5rem;
    background-color: ${Colours.OV_BASE};
    &:last-child {
      margin-right: 0;
    }
  }
`;

const StyledAlert = styled(Alert)`
  && {
    margin: 0.25rem 0 0.5rem 0;
    border-radius: ${Variables.borderRadius.LARGE};
    background-color: ${Colours.OV_YELLOW};
    color: ${Colours.OV_BASE};

    .MuiAlertTitle-root {
      font-weight: bold !important;
    }
  }
`;
