import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import React, { FC, useState } from 'react';
import breakpoints from '../../../design-system/breakpoints';
import { Notification } from '../../../common/model/dto/notification/notification';
import styled from 'styled-components';
import OvTable from './OvTable';
import { NotificationDataUtils } from '../../../common/utils/services/notification-data-utils';
import { useTranslation } from 'react-i18next';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Colours from '../../../design-system/colours';
import OvConfirmationDialog from './OvConfirmationDialog';
import SendIcon from '@mui/icons-material/Send';
import OvButton from '../atoms/OvButton';
import Variables from '../../../design-system/variables';

interface OvReviewInsightDialogProps {
  isOpen: boolean;
  onCancel: () => void;
  onSave: (mode: 'approve' | 'decline') => void;
  notification?: Notification;
}

const OvReviewInsightDialog: FC<OvReviewInsightDialogProps> = ({
  isOpen,
  onCancel,
  onSave,
  notification,
}) => {
  const { t } = useTranslation();
  const [isApproveConfirmationDialogOpen, setIsApproveConfirmationDialogOpen] =
    useState<boolean>(false);
  const [isDeclineConfirmationDialogOpen, setIsDeclineConfirmationDialogOpen] =
    useState<boolean>(false);

  const onClickApprove = () => {
    onSave('approve');
    onCancel();
  };

  const onClickDecline = () => {
    onSave('decline');
    onCancel();
  };

  return (
    <Dialog open={isOpen} onClose={onCancel}>
      <StyledDialogTitle>Review Insight</StyledDialogTitle>
      <StyledDialogContent>
        <TableWrapper>
          <OvTable
            data={NotificationDataUtils.mapNotificationToReviewInsightsFormat(
              notification
            )}
          />
        </TableWrapper>

        <StyledSectionTitle>
          {t('notifications.insightsDescription')}
        </StyledSectionTitle>
        <StyledReasonText>
          {NotificationDataUtils.getInsightDescription(notification)}
        </StyledReasonText>
      </StyledDialogContent>
      <StyledDialogActions>
        <StyledAcceptIconButton
          onClick={() => setIsApproveConfirmationDialogOpen(true)}
        >
          <CheckCircleIcon />
        </StyledAcceptIconButton>
        <StyledRejectIconButton
          onClick={() => setIsDeclineConfirmationDialogOpen(true)}
        >
          <CancelIcon />
        </StyledRejectIconButton>
      </StyledDialogActions>
      <StyledCloseDialogAction>
        <StyledLightOvButton onClick={() => onCancel()}>
          {t('common.actions.close')}
        </StyledLightOvButton>
      </StyledCloseDialogAction>

      <OvConfirmationDialog
        isOpen={isApproveConfirmationDialogOpen}
        onCancel={() => setIsApproveConfirmationDialogOpen(false)}
        onConfirm={onClickApprove}
        title={t('notifications.dialogs.approveInsightConfirmationDialogTitle')}
        description={t(
          'notifications.dialogs.approveInsightConfirmationDialogDescription'
        )}
        icon={<StyledSendIcon />}
      />
      <OvConfirmationDialog
        isOpen={isDeclineConfirmationDialogOpen}
        onCancel={() => setIsDeclineConfirmationDialogOpen(false)}
        onConfirm={onClickDecline}
        title={t('notifications.dialogs.declineInsightConfirmationDialogTitle')}
        description={t(
          'notifications.dialogs.declineInsightConfirmationDialogDescription'
        )}
        icon={<StyledSendIcon />}
      />
    </Dialog>
  );
};

export default OvReviewInsightDialog;

const StyledDialogTitle = styled(DialogTitle)`
  && {
    text-align: center;
    font-weight: bold;
    text-transform: none;
  }
`;

const StyledDialogContent = styled(DialogContent)`
  && {
    width: 100%;
    min-width: 31.25rem !important;
    padding: 0.5rem 1.5rem !important;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 0.75rem;

    @media (min-width: ${breakpoints.sm}) {
      width: 34rem;
    }
  }
`;

const TableWrapper = styled.div`
  flex-grow: 1;
  align-self: flex-start;
  margin-left: 12px;
  width: 100%;
  @media (max-width: ${breakpoints.md}) {
    margin-left: 0;
    margin-top: 12px;
  }
`;

const StyledReasonText = styled.p`
  margin: 1rem 0.25rem;
  font-size: 15px;
`;

const StyledSectionTitle = styled.h4`
  margin: 0.5rem 0 0 0;
`;

const StyledDialogActions = styled(DialogActions)`
  && {
    margin-top: 2rem;
    padding: 0 0 0.5rem 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
`;

const StyledCloseDialogAction = styled(DialogActions)`
  && {
    margin: 3rem 1rem 1rem 0;
    padding: 0 0 0.5rem 1.5rem;
    display: flex;
  }
`;

const StyledAcceptIconButton = styled(IconButton)`
  && {
    color: ${Colours.OV_GREEN};
    height: 5rem;
    width: 5rem;

    .MuiSvgIcon-root {
      width: 5rem;
      height: 5rem;
    }

    &.MuiButtonBase-root {
      padding: 0.375rem;
      border-radius: 50%;
    }
  }
`;

const StyledRejectIconButton = styled(IconButton)`
  && {
    color: ${Colours.OV_RED};
    height: 5rem;
    width: 5rem;

    .MuiSvgIcon-root {
      width: 5rem;
      height: 5rem;
    }

    &.MuiButtonBase-root {
      padding: 0.375rem;
      border-radius: 50%;
    }
  }
`;

const StyledSendIcon = styled(SendIcon)`
  && {
    box-sizing: content-box;
    padding: 0.5rem;
    background: ${Colours.OV_BASE};
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    text-align: center;
    display: block;
    margin: 1rem auto 0 auto;
    color: ${Colours.OV_SEMI_LIGHT};
  }
`;

const StyledLightOvButton = styled(OvButton)`
  && {
    border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
    padding: 0 1rem;
    background-color: ${Colours.OV_WHITE};
    border: 1px solid rgba(1, 39, 70, 0.5);
    color: ${Colours.OV_BASE};
    font-weight: bold;
    text-transform: none;
    max-width: fit-content;
    &:hover {
      border: 1px solid ${Colours.OV_BASE};
      background-color: rgba(1, 39, 70, 0.04);
    }
  }
`;
