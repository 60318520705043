import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Variables from '../../design-system/variables';
import Colours from '../../design-system/colours';
import OvPageTitle from '../UI/molecules/OvPageTitle';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useTranslation } from 'react-i18next';
import usePaginationQueryParams from '../../hooks/use-pagination-query-params';
import useAxios from 'axios-hooks';
import { OrderBy, Paginated, SortDirection } from '../../common/types';
import { Notification } from '../../common/model/dto/notification/notification';
import { ADMIN_NOTIFICATION_ROUTE } from '../../api/backend';
import { isEmpty } from 'lodash';
import OvPagination from '../UI/atoms/OvPagination';
import { PaginationItem } from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import OvNotificationList from '../UI/organisms/OvNotificationList';
import OvNoContent from '../UI/molecules/OvNoContent';
import OvLoadingIndicator from '../UI/atoms/OvLoadingIndicator';
import OvNotificationTypeahead from '../UI/molecules/OvNotificationTypeahead';
import { useHistory, useParams } from 'react-router-dom';
import { getNotificationsTypeahead } from '../../services/admin/admin-notification.service';
import OvNotificationSearchFilter from '../UI/organisms/OvNotificationSearchFilter';
import { FilterModel } from '../../common/model/ui/filter.model';
import { FilterType } from '../../common/model/ui/filter.type';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import { NotificationContentCategory } from '../../common/model/dto/notification/notification-content';

const NOTIFICATION_LIST_LIMIT = 10;

const AdminNotificationList = () => {
  const { t } = useTranslation();
  const history = useHistory();
  let { userId }: any = useParams();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isApprovementPending, setIsApprovementPending] = useQueryParam(
    'is_approvement_pending',
    withDefault(StringParam, '')
  );
  const [isApproved, setIsApproved] = useQueryParam(
    'is_approved',
    withDefault(StringParam, '')
  );
  const [category, setCategory] = useQueryParam(
    'category',
    withDefault(StringParam, '')
  );
  const [isRequestLoading, setIsRequestLoading] = useState<boolean>(false);

  const { offset, orderBy, orderDir, setOffset, setOrderBy, setOrderDir } =
    usePaginationQueryParams();

  const filters: FilterModel[] = [
    {
      fieldName: 'is_approvement_pending',
      label: 'notifications.fields.isApprovementPending',
      type: FilterType.radio,
      options: [
        {
          label: 'common.actions.yes',
          value: true,
        },
        {
          label: 'common.actions.no',
          value: false,
        },
        {
          label: 'common.actions.notSelected',
          value: '',
        },
      ],
      value: isApprovementPending,
    },
    {
      fieldName: 'is_approved',
      label: 'notifications.fields.isApproved',
      type: FilterType.radio,
      options: [
        {
          label: 'common.actions.yes',
          value: true,
        },
        {
          label: 'common.actions.no',
          value: false,
        },
        {
          label: 'common.actions.notSelected',
          value: '',
        },
      ],
      value: isApproved,
    },
    {
      fieldName: 'category',
      label: 'notifications.fields.category',
      type: FilterType.select,
      options: Object.values(NotificationContentCategory).map((category) => ({
        label: category,
        value: category,
      })),
      value: category,
    },
  ];

  const [{ data, loading, error }, refetch] = useAxios<Paginated<Notification>>(
    {
      url: `/${ADMIN_NOTIFICATION_ROUTE}`,
      method: 'get',
      params: {
        limit: NOTIFICATION_LIST_LIMIT,
        offset,
        order_by: isEmpty(orderBy) ? undefined : orderBy,
        order_dir: isEmpty(orderDir) ? undefined : orderDir,
        is_approvement_pending: isApprovementPending,
        is_approved: isApproved,
        category,
        user_id: userId,
      },
    },
    { useCache: false, manual: !orderBy && !orderDir }
  );

  useEffect(() => {
    setOffset(0);
    setOrderBy('is_approvement_pending');
    setOrderDir('desc');
  }, [setOrderDir, setOrderBy, setOffset]);

  const notifications = data?.docs ?? [];
  const total = data?.total ?? 0;
  const isLoading = loading || isRequestLoading;

  const goToPage = (_: any, value: number) => {
    setOffset((value - 1) * NOTIFICATION_LIST_LIMIT);
  };

  const pageCount = () => Math.ceil(total / NOTIFICATION_LIST_LIMIT);

  const onSortByColumn = (orderBy: OrderBy) => {
    setOrderBy(orderBy.order_by);
    setOrderDir(orderBy.order_dir);
  };
  const onNotificationSelect = (notification: Notification) => {
    history.push(`/notifications/${notification?.id}`);
  };

  const fetchNotifications = (searchText: string) => {
    return getNotificationsTypeahead(searchText);
  };

  const onFilterChange = (filter: FilterModel) => {
    switch (filter.fieldName) {
      case 'is_approvement_pending':
        setOffset(0);
        setIsApprovementPending(filter.value);
        break;

      case 'category':
        setOffset(0);
        setCategory(filter.value);
        break;

      case 'is_approved':
        setOffset(0);
        setIsApproved(filter.value);
        break;

      default:
        break;
    }
  };

  const hasAppliedListFilters = (): boolean => {
    return filters?.some(({ value }) => value !== '');
  };

  const onFilterClear = () => {
    setIsApprovementPending(null);
    setIsApproved(null);
    setCategory(null);
  };

  return (
    <Container>
      <CenterPane>
        <HeaderItems>
          <TitleAndSearch>
            <OvPageTitle
              title={t('app.subPages.menu.notifications')}
              icon={<NotificationsIcon />}
            />
            {!userId && (
              <StyledNotificationTypeAhead
                placeholder={t('common.actions.search')}
                onNotificationSelect={onNotificationSelect}
                fetchNotifications={fetchNotifications}
              />
            )}
          </TitleAndSearch>
        </HeaderItems>

        <ActionBar>
          <PaginationWrapper>
            {total > NOTIFICATION_LIST_LIMIT && (
              <OvPagination
                page={(offset ?? 0) / NOTIFICATION_LIST_LIMIT + 1}
                onChange={goToPage}
                count={pageCount()}
                renderItem={(item) => (
                  <PaginationItem
                    components={{ previous: ArrowBack, next: ArrowForward }}
                    {...item}
                  />
                )}
                shape="rounded"
              />
            )}
          </PaginationWrapper>
        </ActionBar>
        {error && <StyledErrorMessage>{error.message}</StyledErrorMessage>}
        {errorMessage && (
          <StyledErrorMessage>{errorMessage}</StyledErrorMessage>
        )}

        {notifications.length > 0 ? (
          <OvNotificationList
            notifications={notifications}
            onSort={onSortByColumn}
            orderBy={orderBy}
            orderDir={orderDir as SortDirection}
            refetch={refetch}
            setErrorMessage={setErrorMessage}
            setIsRequestLoading={setIsRequestLoading}
          />
        ) : (
          !isLoading && (
            <OvNoContent>
              {t('notifications.emptyNotificationList')}
            </OvNoContent>
          )
        )}

        {isLoading && <OvLoadingIndicator position="fixed" />}
      </CenterPane>

      <OvNotificationSearchFilter
        filters={filters}
        onHandleFilterValueChange={onFilterChange}
        hasAppliedFilters={hasAppliedListFilters()}
        isOpened={true}
        removeFilters={onFilterClear}
      />
    </Container>
  );
};

export default AdminNotificationList;

const Container = styled.div`
  display: flex;
`;

const CenterPane = styled.div`
  flex-grow: 1;
  margin: 0 auto;
  padding: 0 1rem;
  margin-left: 1rem;
  box-shadow: ${Variables.boxShadow.defaultBox};
  background-color: ${Colours.WHITE};
  padding: 1.5rem;
  border-radius: ${Variables.borderRadius.LARGE};
`;

const HeaderItems = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
`;

const TitleAndSearch = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const ActionBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
`;

const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledErrorMessage = styled.p`
  color: ${Colours.OV_RED};
  margin: 10px 0 24px 0;
  display: flex;
  align-items: center;
`;

const StyledNotificationTypeAhead = styled(OvNotificationTypeahead)`
  max-width: 100%;
  min-width: 15rem;
  width: 20%;
  border-radius: ${Variables.borderRadius.SMALL};
  font-size: 0.9rem;

  & input {
    padding: 0.4rem !important;
    background: ${Colours.WHITE};
  }
`;
