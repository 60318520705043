export enum Resource {
  Application = 'application',
  User = 'user',
  DailyLog = 'daily_log',
  Clinic = 'clinic',
  ClinicLocation = 'clinic_location',
  ClinicPatient = 'clinic_patient',
  ClinicProvider = 'clinic_provider',
  Scan = 'scan',
  JournalEntry = 'journal_entry',
  DailyData = 'daily_data',
  Order = 'order',
  OrderActivity = 'order_activity',
  DemoAccount = 'demo_account',
  Article = 'article',
  Lot = 'lot',
  Product = 'product',
  ScanningPlan = 'scanning_plan',
  UserAuditLogActivity = 'user_audit_log_activity',
  ClinicAuditLogActivity = 'clinic_audit_log_activity',
  NotificationContent = 'notification_content',
  Notification = 'notification',
}
