export enum UserDetailTabType {
  USER_INFO = 'user-info',
  CALENDAR = 'calendar',
  REPORTS = 'reports',
  HORMONE_CHARTS = 'hormone-charts',
  BASELINES = 'baselines',
  SCANS = 'scans',
  AUDIT_ACTIVITY = 'audit-activity',
  NOTIFICATIONS = 'notifications',
}
