import React, { FC } from 'react';
import { NotificationContentCategory } from '../../../common/model/dto/notification/notification-content';
import styled from 'styled-components';
import Colours from '../../../design-system/colours';
import Variables from '../../../design-system/variables';
import { NotificationContentDataUtils } from '../../../common/utils/services/notification-content-utils';

interface OvNotificationsTypeaheadListItemProps {
  items: {
    notificationContentTitle: string;
    email: string;
    notificationContentCategory: NotificationContentCategory;
  }[];
}

const OvNotificationsTypeaheadListItem: FC<
  OvNotificationsTypeaheadListItemProps
> = ({ items, ...props }) => {
  return (
    <>
      {items.map((item, index) => {
        const notificationContentCategoryProps =
          NotificationContentDataUtils.getNotificationContentProps(
            item.notificationContentCategory
          );

        return (
          <Wrapper {...props} key={`${index}-${item.email}`}>
            <Item key={index}>
              <TitleLabel key={index}>
                {item.notificationContentTitle}
              </TitleLabel>
              <ConentLabel>{item.email}</ConentLabel>
              <ConentLabel
                style={{
                  color:
                    notificationContentCategoryProps.notificationContentColor,
                }}
              >
                {
                  notificationContentCategoryProps.notificationContentCategoryDisplay
                }
              </ConentLabel>
            </Item>
          </Wrapper>
        );
      })}
    </>
  );
};

export default OvNotificationsTypeaheadListItem;

const Wrapper = styled.div`
  min-width: 20rem;
  color: ${Colours.OV_SEMI_LIGHT};
  background-color: ${Colours.OV_BASE} !important;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  width: 100%;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid ${Colours.OV_GRAY};

  &:last-child {
    border-bottom: 0;
    margin-bottom: 0;
  }

  &:hover {
    background-color: ${Colours.OV_BASE_HOVER} !important;
  }
`;

const Item = styled.div`
  margin: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const TitleLabel = styled.span`
  font-weight: bold;
  font-size: ${Variables.fontSizes.MEDIUM};
`;

const ConentLabel = styled.span`
  font-size: ${Variables.fontSizes.SMALL};
`;
