import { useEffect } from 'react';
import styled from 'styled-components';
import Colours from '../../design-system/colours';
import OvPagination from '../UI/atoms/OvPagination';
import { PaginationItem } from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import OvLoadingIndicator from '../UI/atoms/OvLoadingIndicator';
import useAxios from 'axios-hooks';
import usePaginationQueryParams from '../../hooks/use-pagination-query-params';
import { Paginated } from '../../common/types';
import { isEmpty } from 'lodash';
import { Scan } from '../../common/model/dto/scan';
import { ADMIN_SCANS_ROUTE } from '../../api/backend';
import { UserInfo } from '../../common/model/dto/user-info';
import OvAdminScanList from '../UI/organisms/OvAdminScanList';

const SCAN_LIST_LIMIT = 10;

const UserScanList = ({ selectedUser }: { selectedUser: UserInfo }) => {
  const { offset, orderBy, orderDir, setOffset, setOrderBy, setOrderDir } =
    usePaginationQueryParams();

  const [{ data, loading, error }, refetch] = useAxios<Paginated<Scan>>(
    {
      url: `/${ADMIN_SCANS_ROUTE}`,
      method: 'get',
      params: {
        user_id: selectedUser.id,
        limit: SCAN_LIST_LIMIT,
        offset,
        order_by: isEmpty(orderBy) ? undefined : orderBy,
        order_dir: isEmpty(orderDir) ? undefined : orderDir,
      },
    },
    { useCache: false }
  );

  const scans = data?.docs ?? [];
  const total = data?.total ?? 0;

  const goToPage = (_: any, value: number) => {
    setOffset((value - 1) * SCAN_LIST_LIMIT);
  };

  const pageCount = () => Math.ceil(total / SCAN_LIST_LIMIT);

  useEffect(() => {
    setOrderBy('day');
    setOrderDir('desc');
  }, [setOrderBy, setOrderDir]);

  return (
    <Container>
      <CenterPane>
        <ScanActions>
          {total > SCAN_LIST_LIMIT && (
            <OvPagination
              page={(offset ?? 0) / SCAN_LIST_LIMIT + 1}
              onChange={goToPage}
              count={pageCount()}
              renderItem={(item) => (
                <PaginationItem
                  components={{ previous: ArrowBack, next: ArrowForward }}
                  {...item}
                />
              )}
              shape="rounded"
            />
          )}
        </ScanActions>

        {error && <StyledErrorMessage>{error.message}</StyledErrorMessage>}

        <OvAdminScanList
          scans={scans}
          selectedUserId={selectedUser.id}
          showDetailsAction={true}
          showOperation={true}
          refetch={refetch}
        />

        {loading && <OvLoadingIndicator position="fixed" />}
      </CenterPane>
    </Container>
  );
};

export default UserScanList;

const Container = styled.section`
  display: flex;
`;

const CenterPane = styled.div`
  flex-grow: 1;
  margin: 0 auto;
  padding: 0 1rem;
`;

const ScanActions = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 1.5rem;
`;

const StyledErrorMessage = styled.p`
  color: ${Colours.OV_RED};
  margin: 10px 0 24px 0;
  display: flex;
  align-items: center;
`;
