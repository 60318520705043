export interface NotificationContent {
  id?: string;
  title: string;
  category: NotificationContentCategory;
  actions?: NotificationAction[];
  short_description?: string;
  full_description?: string;
  disclaimer?: string;
  tags?: string[];
  params?: any;
  insights_type?: InsightsType;
  image_url?: string;
  is_published: boolean;
  number_of_created_notifications?: number;
  app_version?: string;
  created_at?: Date;
  updated_at?: Date;
}

export enum NotificationContentCategory {
  FROM_OOVA = 'from_oova',
  INSIGHTS = 'insights',
  BLOG_POST = 'blog_post',
  APP_UPDATES = 'app_updates',
  STUDIES = 'studies',
  OFFERS = 'offers',
}

export enum InsightsType {
  CYCLE_LENGTH_MODIFICATION = 'cycle_length_modification',
  CHECK_FOR_PREGNANCY = 'check_for_pregnancy',
  PREV_CYCLE_OVULATORY_OR_NOT = 'prev_cycle_ovulatory_or_not',
  LOWER_PERSONAL_MAX_LH_PDG = 'lower_personal_max_lh_pdg',
}

export const SelectableInsightsTypes = [InsightsType.CYCLE_LENGTH_MODIFICATION];

export interface NotificationAction {
  cta: string;
  button_style: NotificationButtonStyle;
  action_url?: string;
  type: NotificationActionType;
}

export enum NotificationActionType {
  dismiss = 'dismiss',
  accept = 'accept',
}

export enum NotificationButtonStyle {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  DESTRUCTIVE = 'destructive',
}
