import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import LoginPage from './components/pages/LoginPage';
import ApplicationBasePage from './components/pages/ApplicationBasePage';
import NotFoundPage from './components/pages/NotFoundPage';
import './App.scss';
import './styles/mui-tabs-style-override.scss';
import AuthRoute from './common/route-guards/AuthRoute';
import useUserAuthStatus from './firebase/hooks/use-user-auth-status';
import './interceptors/http-service.interceptor';
import AcceptInvitationPage, {
  AcceptInvitationRequestType,
} from './components/pages/AcceptInvitationPage';
import SetPasswordPage from './components/pages/SetPasswordPage';
import { Loader } from '@googlemaps/js-api-loader';
import { CompatRouter } from 'react-router-dom-v5-compat';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';
import { NotificationProvider } from './context/NotificationContext';

const App = () => {
  const isInitialUserFetch = useUserAuthStatus();
  const reactMapsApiKey = process.env.REACT_APP_MAPS_API_KEY;

  useEffect(() => {
    if (reactMapsApiKey) {
      const loader = new Loader({
        apiKey: reactMapsApiKey,
        version: 'weekly',
        libraries: ['places'],
      });

      const loadLibrary = async () => {
        await loader.importLibrary('places');
      };

      loadLibrary();
    }
  }, [reactMapsApiKey]);

  if (isInitialUserFetch) {
    return null;
  }

  return (
    <Router>
      <CompatRouter>
        <QueryParamProvider adapter={ReactRouter5Adapter}>
          <Switch>
            <AuthRoute
              path="/login"
              type="public"
              permissions={['none']}
              redirectPath="/users"
            >
              <LoginPage />
            </AuthRoute>

            <AuthRoute
              path="/set-password/:token"
              type="public"
              permissions={['none']}
            >
              <SetPasswordPage />
            </AuthRoute>

            <AuthRoute
              path="/providers/approve/:token"
              type="public"
              permissions={['none']}
            >
              <AcceptInvitationPage
                acceptInvitationRequestType={
                  AcceptInvitationRequestType.CLINIC_LOCATION_PROVIDER
                }
              />
            </AuthRoute>

            <AuthRoute
              path="/patients/approve/:token"
              type="public"
              permissions={['none']}
            >
              <AcceptInvitationPage
                acceptInvitationRequestType={
                  AcceptInvitationRequestType.CLINIC_LOCATION_PATIENT
                }
              />
            </AuthRoute>

            <AuthRoute
              path="/provider-patients/approve/:token"
              type="public"
              permissions={['none']}
            >
              <AcceptInvitationPage
                acceptInvitationRequestType={
                  AcceptInvitationRequestType.PROVIDER_PATIENT
                }
              />
            </AuthRoute>

            <NotificationProvider>
              <AuthRoute
                path="/"
                type="protected"
                permissions={['user']}
                redirectPath="/login"
              >
                <ApplicationBasePage />
              </AuthRoute>
            </NotificationProvider>

            <Route path="/page-not-found" exact>
              <NotFoundPage />
            </Route>
            <Redirect to="/page-not-found" />
          </Switch>
        </QueryParamProvider>
      </CompatRouter>
    </Router>
  );
};

export default App;
