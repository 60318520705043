import axios from 'axios';
import { ADMIN_NOTIFICATION_ROUTE } from '../../api/backend';
import { PublishNotificationRequest } from '../../common/model/dto/notification/publish-notification-request';
import { stringify } from 'query-string';
import { Paginated } from '../../common/types';
import { Notification } from '../../common/model/dto/notification/notification';

export const getNotificationsTypeahead = async (searchText: string) => {
  const query = stringify({
    search_text: searchText.toUpperCase().split(' ').join(','),
    limit: 10,
  });

  const { data } = await axios.get<Paginated<Notification>>(
    `${ADMIN_NOTIFICATION_ROUTE}?${query}`
  );

  return data.docs;
};

export const createNotifications = async (
  notificationContentId: string,
  publishNotificationRequest: PublishNotificationRequest
) => {
  return await axios.post(
    `${ADMIN_NOTIFICATION_ROUTE}/notification-contents/${notificationContentId}/create`,
    publishNotificationRequest
  );
};

export const approvePendingNotification = async (notificationId: string) => {
  return await axios.patch(
    `${ADMIN_NOTIFICATION_ROUTE}/${notificationId}/approve`
  );
};

export const declinePendingNotification = async (notificationId: string) => {
  return await axios.patch(
    `${ADMIN_NOTIFICATION_ROUTE}/${notificationId}/decline`
  );
};

export const deleteNotification = async (notificationId: string) => {
  return await axios.delete(`${ADMIN_NOTIFICATION_ROUTE}/${notificationId}`);
};
