import React, { FC } from 'react';
import { FilterModel } from '../../../common/model/ui/filter.model';
import OvListFilter from './OvListFilter';

interface OvNotificationSearchFilterProps {
  filters: FilterModel[];
  hasAppliedFilters: boolean;
  isOpened: boolean;
  removeFilters: () => void;
  onHandleFilterValueChange: (filter: FilterModel) => void;
}

const OvNotificationSearchFilter: FC<OvNotificationSearchFilterProps> = ({
  filters,
  hasAppliedFilters,
  isOpened,
  removeFilters,
  onHandleFilterValueChange,
}) => {
  return (
    <OvListFilter
      filters={filters}
      isOpened={isOpened}
      hasAppliedFilters={hasAppliedFilters}
      onRemoveFilters={removeFilters}
      onHandleFilterValueChange={onHandleFilterValueChange}
    />
  );
};

export default OvNotificationSearchFilter;
