import React, { FC, useContext, useState } from 'react';
import { Notification } from '../../../common/model/dto/notification/notification';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import OvButton from '../atoms/OvButton';
import Variables from '../../../design-system/variables';
import Colours from '../../../design-system/colours';
import OvListTableField from '../atoms/OvListTableField';
import StringUtils from '../../../common/utils/services/string-utils';
import DateUtils from '../../../common/utils/services/date-utils';
import { IconButton, Tooltip } from '@mui/material';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import OvReviewInsightDialog from '../molecules/OvReviewInsightDialog';
import {
  approvePendingNotification,
  declinePendingNotification,
} from '../../../services/admin/admin-notification.service';
import { NotificationContext } from '../../../context/NotificationContext';
import { NotificationContentCategory } from '../../../common/model/dto/notification/notification-content';
import { NotificationContentDataUtils } from '../../../common/utils/services/notification-content-utils';

const OvNotificationListRow: FC<{
  notification: Notification;
  refetch: any;
  setErrorMessage: (error: string) => void;
  setIsRequestLoading: (isLoading: boolean) => void;
}> = ({ notification, refetch, setErrorMessage, setIsRequestLoading }) => {
  const { t } = useTranslation();
  const [isReviewDialogOpen, setIsReviewDialogOpen] = useState<boolean>(false);
  const { refetchPendingNotificationCount } = useContext(NotificationContext);

  const notificationContentCategoryProps =
    NotificationContentDataUtils.getNotificationContentProps(
      notification.notification_content.category
    );

  const handleInsightReview = async (mode: 'approve' | 'decline') => {
    if (notification?.id) {
      if (mode === 'approve') {
        try {
          setErrorMessage('');
          setIsRequestLoading(true);
          await approvePendingNotification(notification?.id);
          refetch();
        } catch (error: any) {
          setErrorMessage(error.message);
        } finally {
          refetchPendingNotificationCount();
          setIsRequestLoading(false);
        }
      } else {
        try {
          setErrorMessage('');
          setIsRequestLoading(true);
          await declinePendingNotification(notification?.id);
          refetch();
        } catch (error: any) {
          setErrorMessage(error.message);
        } finally {
          refetchPendingNotificationCount();
          setIsRequestLoading(false);
        }
      }
    }
  };

  return (
    <>
      <OvListTableField
        style={{
          color: notification.is_approvement_pending
            ? Colours.OV_YELLOW
            : 'inherit',
        }}
      >
        {StringUtils.displayValue(
          `${notification?.notification_content?.title || '-'}`
        )}
      </OvListTableField>
      <OvListTableField
        style={{
          color: notification.is_approvement_pending
            ? Colours.OV_YELLOW
            : 'inherit',
        }}
      >
        {StringUtils.displayValue(`${notification?.user?.email || '-'}`)}
      </OvListTableField>
      <OvListTableField
        style={{
          color: notificationContentCategoryProps.notificationContentColor,
        }}
      >
        {StringUtils.displayValue(
          notificationContentCategoryProps.notificationContentCategoryDisplay
        )}
      </OvListTableField>
      <OvListTableField
        style={{
          color: notification.is_approvement_pending
            ? Colours.OV_YELLOW
            : 'inherit',
        }}
      >
        {StringUtils.getPrettifiedBooleanValue(
          notification?.is_approvement_pending
        )}
      </OvListTableField>
      <OvListTableField>
        {StringUtils.getPrettifiedBooleanValue(notification?.is_approved)}
      </OvListTableField>
      <OvListTableField
        style={{
          color: notification.is_approvement_pending
            ? Colours.OV_YELLOW
            : 'inherit',
        }}
      >
        {notification?.created_at
          ? DateUtils.formatDate(
              notification?.created_at as unknown as Date,
              'MMMM DD, YYYY, HH:mm:ss'
            )
          : '-'}
      </OvListTableField>
      <OvListTableField
        style={{
          textAlign: 'right',
        }}
      >
        {notification?.notification_content.category ===
          NotificationContentCategory.INSIGHTS &&
        notification.is_approvement_pending === true ? (
          <Tooltip title={t('notifications.actions.reviewInsight') || ''}>
            <StyledIconButton
              onClick={(event) => setIsReviewDialogOpen(true)}
              className="actionIcon"
            >
              <FactCheckIcon />
            </StyledIconButton>
          </Tooltip>
        ) : (
          '-'
        )}
      </OvListTableField>
      <OvListTableField>
        <StyledOvButton>
          <NavLink to={`/notifications/${notification.id}`}>
            {t('common.actions.seeDetails')}
          </NavLink>
        </StyledOvButton>
      </OvListTableField>

      <OvReviewInsightDialog
        isOpen={isReviewDialogOpen}
        onCancel={() => setIsReviewDialogOpen(false)}
        onSave={handleInsightReview}
        notification={notification}
      />
    </>
  );
};

export default OvNotificationListRow;

const StyledOvButton = styled(OvButton)`
  && {
    border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
    padding: 0;
    margin: 0;
    background-color: ${Colours.OV_BASE};
    white-space: nowrap;

    &:hover {
      a {
        color: ${Colours.OV_BASE};
      }
    }

    a {
      border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
      padding: 0.25rem 1rem;
      text-decoration: none;
      text-transform: none;
      font-weight: bold;
      font-size: 0.75rem;
      color: ${Colours.OV_WHITE};
    }
  }
`;

const StyledIconButton = styled(IconButton)`
  && {
    color: ${Colours.OV_BASE};
    height: 2rem;
    width: 2rem;

    .MuiSvgIcon-root {
      width: 1.5rem;
      height: 1.5rem;
    }

    &.MuiButtonBase-root {
      padding: 0.375rem;
      border-radius: 50%;
    }
  }
`;
