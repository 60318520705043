import React, { useEffect, useState } from 'react';
import usePaginationQueryParams from '../../hooks/use-pagination-query-params';
import useAxios from 'axios-hooks';
import { OrderBy, Paginated, SortDirection } from '../../common/types';
import {
  NotificationContent,
  NotificationContentCategory,
} from '../../common/model/dto/notification/notification-content';
import { ADMIN_NOTIFICATION_CONTENTS_ROUTE } from '../../api/backend';
import { NotificationContentFields } from '../../firebase/document-field.enums';
import styled from 'styled-components';
import Colours from '../../design-system/colours';
import Variables from '../../design-system/variables';
import OvPageTitle from '../UI/molecules/OvPageTitle';
import OvPagination from '../UI/atoms/OvPagination';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { PaginationItem } from '@mui/material';
import OvListTable from '../UI/atoms/OvListTable';
import OvListTableRow from '../UI/atoms/OvListTableRow';
import OvListTableHeaderField from '../UI/atoms/OvListTableHeaderField';
import OvLoadingIndicator from '../UI/atoms/OvLoadingIndicator';
import OvNotificationContentListRow from '../UI/organisms/OvNotificationContentListRow';
import OvErrorMessage from '../UI/atoms/OvErrorMessage';
import OvButton from '../UI/atoms/OvButton';
import { useTranslation } from 'react-i18next';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import OvCreateNotificationsContentDialog from '../UI/molecules/OvCreateNotificationsContentDialog';
import { CreateNotificationContentRequest } from '../../common/model/dto/notification/create-notification-content-request';
import { createNotificationContent } from '../../services/admin/admin-notification-content.service';
import OvNoContent from '../UI/molecules/OvNoContent';
import MarkUnreadChatAltIcon from '@mui/icons-material/MarkUnreadChatAlt';
import { FilterModel } from '../../common/model/ui/filter.model';
import { FilterType } from '../../common/model/ui/filter.type';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import OvNotificationContentSearchFilter from '../UI/organisms/OvNotificationContentSearchFilter';

const NOTIFICATION_CONTENTS_LIST_LIMIT = 10;

const NotificationContentsList = () => {
  const { t } = useTranslation();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [isRequestLoading, setIsRequestLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { offset, orderBy, orderDir, setOffset, setOrderBy, setOrderDir } =
    usePaginationQueryParams();
  const [isPublished, setIsPublished] = useQueryParam(
    'is_published',
    withDefault(StringParam, '')
  );
  const [category, setCategory] = useQueryParam(
    'category',
    withDefault(StringParam, '')
  );

  const filters: FilterModel[] = [
    {
      fieldName: 'is_published',
      label: 'notificationContents.fields.isPublished',
      type: FilterType.radio,
      options: [
        {
          label: 'common.actions.yes',
          value: true,
        },
        {
          label: 'common.actions.no',
          value: false,
        },
        {
          label: 'common.actions.notSelected',
          value: '',
        },
      ],
      value: isPublished,
    },
    {
      fieldName: 'category',
      label: 'notifications.fields.category',
      type: FilterType.select,
      options: Object.values(NotificationContentCategory).map((category) => ({
        label: category,
        value: category,
      })),
      value: category,
    },
  ];

  const [{ data, loading, error }, refetch] = useAxios<
    Paginated<NotificationContent>
  >(
    {
      url: `${ADMIN_NOTIFICATION_CONTENTS_ROUTE}`,
      method: 'GET',
      params: {
        limit: NOTIFICATION_CONTENTS_LIST_LIMIT,
        offset,
        order_by: orderBy,
        order_dir: orderDir,
        category,
        is_published: isPublished,
      },
    },
    { useCache: false, manual: !orderBy && !orderDir }
  );

  useEffect(() => {
    setOffset(0);
    setOrderBy('created_at');
    setOrderDir('desc');
  }, [setOrderDir, setOrderBy, setOffset]);

  const notificationContentList: NotificationContent[] = data?.docs ?? [];
  const total = data?.total ?? 0;

  const pageCount: () => number = () =>
    Math.ceil((data?.total ?? 0) / NOTIFICATION_CONTENTS_LIST_LIMIT);

  const goToPage = (event: any, value: number) => {
    setOffset((value - 1) * NOTIFICATION_CONTENTS_LIST_LIMIT);
  };

  const getCurrentSortDirection = (
    columnName: NotificationContentFields
  ): SortDirection =>
    columnName === orderBy ? (orderDir as SortDirection) : 'none';

  const onSortByColumn = (orderBy: OrderBy) => {
    setOrderBy(orderBy.order_by);
    setOrderDir(orderBy.order_dir);
  };

  const handleOnSave = async (
    createNotificationContentRequest: CreateNotificationContentRequest
  ) => {
    try {
      setErrorMessage('');
      setIsRequestLoading(true);
      await createNotificationContent(createNotificationContentRequest);
      setIsRequestLoading(false);
      refetch();
    } catch (error: any) {
      setErrorMessage(error.message);
    } finally {
      setIsRequestLoading(false);
      setIsDialogOpen(false);
    }
  };

  const onFilterChange = (filter: FilterModel) => {
    switch (filter.fieldName) {
      case 'is_published':
        setOffset(0);
        setIsPublished(filter.value);
        break;

      case 'category':
        setOffset(0);
        setCategory(filter.value);
        break;

      default:
        break;
    }
  };

  const hasAppliedListFilters = (): boolean => {
    return filters?.some(({ value }) => value !== '');
  };

  const onFilterClear = () => {
    setIsPublished(null);
    setCategory(null);
  };

  const isLoading = loading || isRequestLoading;

  return (
    <Container>
      <CenterPane>
        <HeaderItems>
          <TitleAndSearch>
            <OvPageTitle
              title={t('app.subPages.menu.notificationContents')}
              icon={<MarkUnreadChatAltIcon />}
            />
          </TitleAndSearch>

          <StyledOvButton onClick={() => setIsDialogOpen(true)}>
            <AddCircleIcon style={{ marginRight: '5' }} />
            {t('common.actions.addNew')}
          </StyledOvButton>
        </HeaderItems>

        <ActionBar>
          <PaginationWrapper>
            {total > NOTIFICATION_CONTENTS_LIST_LIMIT && (
              <OvPagination
                page={(offset ?? 0) / NOTIFICATION_CONTENTS_LIST_LIMIT + 1}
                onChange={goToPage}
                count={pageCount()}
                renderItem={(item) => (
                  <PaginationItem
                    components={{ previous: ArrowBack, next: ArrowForward }}
                    {...item}
                  />
                )}
                shape="rounded"
              />
            )}
          </PaginationWrapper>
        </ActionBar>

        {error && <OvErrorMessage message={error.message} />}
        {errorMessage && <OvErrorMessage message={errorMessage} />}

        <OvListTableWrapper>
          <OvListTable>
            <OvListTableRow>
              <OvListTableHeaderField
                sortDirection={getCurrentSortDirection(
                  NotificationContentFields.title
                )}
                sortBy={NotificationContentFields.title}
                onSort={onSortByColumn}
              >
                {t('notificationContents.fields.title')}
              </OvListTableHeaderField>
              <OvListTableHeaderField
                sortDirection={getCurrentSortDirection(
                  NotificationContentFields.category
                )}
                sortBy={NotificationContentFields.category}
                onSort={onSortByColumn}
              >
                {t('notificationContents.fields.category')}
              </OvListTableHeaderField>

              <OvListTableHeaderField>
                {t('notificationContents.fields.isPublished')}
              </OvListTableHeaderField>
              <OvListTableHeaderField>
                {t('notificationContents.fields.numberOfCreatedNotifications')}
              </OvListTableHeaderField>
              <OvListTableHeaderField
                sortDirection={getCurrentSortDirection(
                  NotificationContentFields.created_at
                )}
                sortBy={NotificationContentFields.created_at}
                onSort={onSortByColumn}
              >
                {t('notificationContents.fields.createdAt')}
              </OvListTableHeaderField>
              <OvListTableHeaderField>
                {t('common.action')}
              </OvListTableHeaderField>
            </OvListTableRow>

            {!loading &&
              notificationContentList.map((notificationContent) => (
                <OvListTableRow key={notificationContent.id}>
                  <OvNotificationContentListRow
                    notificationContent={notificationContent}
                  />
                </OvListTableRow>
              ))}
          </OvListTable>
        </OvListTableWrapper>

        {isLoading && <OvLoadingIndicator position="fixed" />}

        {!notificationContentList?.length && !loading ? (
          <OvNoContent>{t('common.noContent')}</OvNoContent>
        ) : null}

        <OvCreateNotificationsContentDialog
          isOpen={isDialogOpen}
          onCancel={() => setIsDialogOpen(false)}
          onSave={handleOnSave}
        />
      </CenterPane>

      <OvNotificationContentSearchFilter
        filters={filters}
        onHandleFilterValueChange={onFilterChange}
        hasAppliedFilters={hasAppliedListFilters()}
        isOpened={true}
        removeFilters={onFilterClear}
      />
    </Container>
  );
};

export default NotificationContentsList;

const Container = styled.div`
  display: flex;
`;

const OvListTableWrapper = styled.div`
  padding: 1.5rem 1rem 1rem;
  overflow-x: auto;
`;

const StyledOvButton = styled(OvButton)`
  && {
    display: flex;
    align-items: center;
    text-transform: capitalize;
    padding: 0.125rem 0.7rem 0.125rem 0.35rem;
    border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
    margin-right: 0.5rem;
    &:last-child {
      margin-right: 0;
    }
  }
`;

const CenterPane = styled.div`
  flex-grow: 1;
  margin: 0 auto;
  padding: 0 1rem;
  margin-left: 1rem;
  box-shadow: ${Variables.boxShadow.defaultBox};
  background-color: ${Colours.WHITE};
  padding: 1.5rem;
  border-radius: ${Variables.borderRadius.LARGE};
`;

const HeaderItems = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
`;

const ActionBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
`;

const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TitleAndSearch = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;
