import useAxios from 'axios-hooks';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { NotificationContent } from '../../common/model/dto/notification/notification-content';
import { ADMIN_NOTIFICATION_CONTENTS_ROUTE } from '../../api/backend';
import styled from 'styled-components';
import Variables from '../../design-system/variables';
import breakpoints from '../../design-system/breakpoints';
import Colours from '../../design-system/colours';
import OvButton from '../UI/atoms/OvButton';
import OvTable from '../UI/molecules/OvTable';
import { NotificationContentDataUtils } from '../../common/utils/services/notification-content-utils';
import OvLoadingIndicator from '../UI/atoms/OvLoadingIndicator';
import OvErrorMessage from '../UI/atoms/OvErrorMessage';
import OvPublishNotificationContentDialog from '../UI/molecules/OvPublishNotificationContentDialog';
import { PublishNotificationRequest } from '../../common/model/dto/notification/publish-notification-request';
import { createNotifications } from '../../services/admin/admin-notification.service';
import OvConfirmationDialog from '../UI/molecules/OvConfirmationDialog';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import {
  deleteNotificationContent,
  updateNotificationContent,
} from '../../services/admin/admin-notification-content.service';
import OvNotificationContentDevicePreview from '../UI/molecules/OvNotificationContentDevicePreview';
import OvDeviceView from '../UI/molecules/OvDeviceView';
import useAuthorized from '../../hooks/use-authorized';
import { Resource } from '../../common/model/type/resource.enum';
import OvBackButton from '../UI/atoms/OvBackButton';

const NotificationContentDetails = () => {
  const { notificationContentId }: any = useParams();
  const history = useHistory();
  const { t } = useTranslation();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState<boolean>(false);
  const [isRequestLoading, setIsRequestLoading] = useState<boolean>(false);
  const [{ data, loading, error }, refetch] = useAxios<NotificationContent>(
    {
      url: `${ADMIN_NOTIFICATION_CONTENTS_ROUTE}/${notificationContentId}`,
      method: 'GET',
    },
    { useCache: false, manual: !notificationContentId }
  );
  const [errorMessage, setErrorMessage] = useState<string>('');

  const selectedNotificationContent = data ?? undefined;

  const isLoading = loading || isRequestLoading;

  const notificationContentOperations = useAuthorized(
    Resource.NotificationContent
  );

  const notificationContentUpdateAllowed =
    notificationContentOperations.update ||
    notificationContentOperations.supervise;

  const handleOnPublish = async (
    publishNotificationContentRequest: PublishNotificationRequest
  ) => {
    if (notificationContentId) {
      try {
        setErrorMessage('');
        setIsRequestLoading(true);
        await createNotifications(
          notificationContentId,
          publishNotificationContentRequest
        );
        refetch();
      } catch (error: any) {
        setErrorMessage(error.message);
      } finally {
        setIsDialogOpen(false);
        setIsRequestLoading(false);
      }
    }
  };

  const handleOnUpdate = async (updatedProperties: any) => {
    if (notificationContentId) {
      try {
        setErrorMessage('');
        setIsRequestLoading(true);
        await updateNotificationContent(
          notificationContentId,
          updatedProperties
        );
        refetch();
      } catch (error: any) {
        setErrorMessage(error.message);
      } finally {
        setIsDialogOpen(false);
        setIsRequestLoading(false);
      }
    }
  };

  const handleOnDelete = async () => {
    if (notificationContentId) {
      setIsConfirmationDialogOpen(false);
      setIsRequestLoading(true);
      await deleteNotificationContent(notificationContentId);
      history.push('/notification-contents');
      setIsRequestLoading(false);
    }
  };

  return (
    <DetailPageWrapper>
      <DetailsHeader>
        <DetailsWrapper>
          <OvBackButton label={t('common.actions.back')} />
          <NotificationContentTitle>
            {selectedNotificationContent?.title}
          </NotificationContentTitle>
        </DetailsWrapper>

        <CtaWrapper>
          <StyledOvButton
            disabled={selectedNotificationContent?.is_published}
            onClick={() => setIsDialogOpen(true)}
          >
            {t('notificationContents.publishNotificationContent')}
          </StyledOvButton>
          <StyledOvButton onClick={() => setIsConfirmationDialogOpen(true)}>
            {t('notificationContents.deleteNotificationContent')}
          </StyledOvButton>
        </CtaWrapper>
      </DetailsHeader>

      <span>
        {error && <OvErrorMessage message={error.message} />}
        {errorMessage && <OvErrorMessage message={errorMessage} />}
      </span>

      <DetailPageContentWrapper>
        <TableWrapper>
          <OvTable
            data={NotificationContentDataUtils.mapNotificationContentToTableDataFormat(
              selectedNotificationContent
            )}
            onSaveRowValue={handleOnUpdate}
            editable={notificationContentUpdateAllowed}
          />
        </TableWrapper>
        {selectedNotificationContent && (
          <DeviceViewContainer>
            <OvDeviceView>
              <OvNotificationContentDevicePreview
                notificationContentDetails={selectedNotificationContent}
              />
            </OvDeviceView>
          </DeviceViewContainer>
        )}
      </DetailPageContentWrapper>

      {isLoading && <OvLoadingIndicator position="fixed" />}

      <OvPublishNotificationContentDialog
        isOpen={isDialogOpen}
        onCancel={() => setIsDialogOpen(false)}
        onSave={handleOnPublish}
      />

      <OvConfirmationDialog
        icon={<StyledDeleteIcon />}
        isOpen={isConfirmationDialogOpen}
        onCancel={() => setIsConfirmationDialogOpen(false)}
        onConfirm={handleOnDelete}
        description="notificationContents.dialogs.deleteNotificationContentDescription"
        title="notificationContents.dialogs.deleteNotificationContentTitle"
      />
    </DetailPageWrapper>
  );
};

export default NotificationContentDetails;

const DetailPageWrapper = styled.div`
  margin-left: 1rem;
  box-shadow: ${Variables.boxShadow.defaultBox};
  background-color: ${Colours.WHITE};
  padding: 1.5rem;
  border-radius: ${Variables.borderRadius.SMALL};
`;

const DetailsHeader = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  font-weight: bold;
  justify-content: space-between;
`;

const DetailsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TableWrapper = styled.div`
  flex-grow: 1;
  align-self: flex-start;
  margin-left: 12px;
  width: 100%;
  @media (max-width: ${breakpoints.md}) {
    margin-left: 0;
    margin-top: 12px;
  }
`;

const NotificationContentTitle = styled.h6`
  margin: 0;
  font-weight: bold;
  font-size: ${Variables.fontSizes.LARGE};
`;

const CtaWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledOvButton = styled(OvButton)`
  && {
    display: flex;
    align-items: center;
    text-transform: none;
    padding: 2px 10px;
    padding: 2px 0.625rem;
    border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
    margin-right: 0.5rem;
    background-color: ${Colours.OV_BASE};

    &:last-child {
      margin-right: 0;
    }
  }
`;

const StyledDeleteIcon = styled(DeleteSweepIcon)`
  && {
    box-sizing: content-box;
    padding: 0.5rem;
    background: ${Colours.OV_BASE};
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    text-align: center;
    display: block;
    margin: 1rem auto 0 auto;
    color: ${Colours.OV_SEMI_LIGHT};
  }
`;

const DetailPageContentWrapper = styled.div`
  display: flex;
`;

const DeviceViewContainer = styled.div`
  min-width: 24rem;
  max-width: 100%;
  height: 41rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem;
`;
