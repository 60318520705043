import React, { FC, useCallback, useEffect, useState } from 'react';
import { Notification } from '../../../common/model/dto/notification/notification';
import { Autocomplete, CircularProgress, styled, SxProps } from '@mui/material';
import Colours from '../../../design-system/colours';
import { debounce } from 'lodash';
import StringUtils from '../../../common/utils/services/string-utils';
import OvTextField from '../atoms/OvTextField';
import OvNotificationsTypeaheadListItem from '../atoms/OvNotificationsTypeaheadListItem';
import { NotificationContentCategory } from '../../../common/model/dto/notification/notification-content';

interface OvNotificationTypeaheadProps {
  placeholder?: string;
  optionLabel?: void;
  onNotificationSelect?: (notification: Notification) => void;
  fetchNotifications: (
    searchText: string
  ) => Promise<Notification[]> | undefined;
}

const useStyles = (): { [key: string]: SxProps } => ({
  paper: {
    backgroundColor: Colours.OV_BASE,
  },
});

const OvNotificationTypeahead: FC<OvNotificationTypeaheadProps> = ({
  placeholder,
  optionLabel,
  onNotificationSelect,
  fetchNotifications,
  ...props
}) => {
  const classes = useStyles();
  const [value, setValue] = useState<Notification | null>(null);
  const [options, setOptions] = useState<Notification[]>([]);
  const [inputValue, setInputValue] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const REQUEST_DELAY = 600;

  // eslint-disable-next-line
  const getNotificationsDelayed = useCallback(
    debounce((inputValue, active) => {
      setOptions([]);

      (async () => {
        if (inputValue.length >= 3) {
          setIsLoading(true);
          const notificationList: Notification[] =
            (await fetchNotifications(inputValue)) ?? [];

          if (active) {
            setOptions(notificationList);
            setIsLoading(false);
          }
        }

        setIsLoading(false);
      })();
    }, REQUEST_DELAY),
    []
  );

  useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setOptions(value ? [value] : []);

      return undefined;
    }

    getNotificationsDelayed(inputValue, active);

    return () => {
      active = false;
    };
  }, [inputValue, value, getNotificationsDelayed]);

  const mapNotificationInfoToOption = ({
    notification_content,
    user,
  }: Notification): {
    notificationContentTitle: string;
    email: string;
    notificationContentCategory: NotificationContentCategory;
  }[] => {
    const items: {
      notificationContentTitle: string;
      email: string;
      notificationContentCategory: NotificationContentCategory;
    }[] = [];

    items.push({
      notificationContentTitle: `${StringUtils.truncateString(
        notification_content.title,
        29
      )}`,
      notificationContentCategory: notification_content.category,
      email: user.email,
    });

    return items;
  };

  return (
    <StyledAutocomplete
      {...props}
      freeSolo
      options={options}
      value={value}
      onChange={(event, newValue: Notification | any) => {
        setOptions(value ? [newValue, ...options] : options);
        setValue(newValue);

        if (onNotificationSelect) {
          onNotificationSelect(newValue);
        }
      }}
      getOptionLabel={(option) => {
        if (option) {
          const { notification_content }: Notification = option as Notification;

          if (notification_content.title) {
            return `${StringUtils.truncateString(
              notification_content.title,
              20
            )}`;
          }
        }

        return '';
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      filterOptions={(x) => x}
      filterSelectedOptions
      sx={{ ...classes.paper }}
      renderInput={(params) => (
        <OvTextField
          {...params}
          label={placeholder}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          fullWidth
        />
      )}
      renderOption={(props, option) => {
        let items: {
          notificationContentTitle: string;
          email: string;
          notificationContentCategory: NotificationContentCategory;
        }[] = [];

        if (option) {
          items = mapNotificationInfoToOption(option as Notification);
        }

        return <OvNotificationsTypeaheadListItem {...props} items={items} />;
      }}
    />
  );
};

export default OvNotificationTypeahead;

const StyledAutocomplete = styled(Autocomplete)`
  && {
    .MuiFormControl-root,
    .MuiInputLabel-formControl {
      font-size: 0.875rem;
    }

    .MuiInputBase-formControl {
      font-size: 0.75rem;
      background: ${Colours.WHITE};
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 0;
    }

    :not(.Mui-focused) {
      .MuiInputLabel-formControl {
        top: -10px;
        left: -7px;
      }
    }

    .MuiFormLabel-filled {
      top: 0 !important;
      left: 0 !important;
    }
  }
`;
