import useAxios from 'axios-hooks';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Notification } from '../../common/model/dto/notification/notification';
import { ADMIN_NOTIFICATION_ROUTE } from '../../api/backend';
import styled from 'styled-components';
import Variables from '../../design-system/variables';
import Colours from '../../design-system/colours';
import OvButton from '../UI/atoms/OvButton';
import { useTranslation } from 'react-i18next';
import breakpoints from '../../design-system/breakpoints';
import OvTable from '../UI/molecules/OvTable';
import { NotificationDataUtils } from '../../common/utils/services/notification-data-utils';
import OvLoadingIndicator from '../UI/atoms/OvLoadingIndicator';
import OvDeviceView from '../UI/molecules/OvDeviceView';
import OvNotificationContentDevicePreview from '../UI/molecules/OvNotificationContentDevicePreview';
import OvErrorMessage from '../UI/atoms/OvErrorMessage';
import OvReviewInsightDialog from '../UI/molecules/OvReviewInsightDialog';
import {
  approvePendingNotification,
  declinePendingNotification,
  deleteNotification,
} from '../../services/admin/admin-notification.service';
import OvConfirmationDialog from '../UI/molecules/OvConfirmationDialog';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import { NotificationContext } from '../../context/NotificationContext';
import OvBackButton from '../UI/atoms/OvBackButton';
import useAuthorized from '../../hooks/use-authorized';
import { Resource } from '../../common/model/type/resource.enum';

const NotificationDetails = () => {
  const { notificationId }: any = useParams();
  const { t } = useTranslation();
  const history = useHistory();
  let location = useLocation();
  const [isReviewDialogOpen, setIsReviewDialogOpen] = useState<boolean>(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [isRequestLoading, setIsRequestLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [prevPath, setPrevPath] = React.useState<string>('');
  const { refetchPendingNotificationCount } = useContext(NotificationContext);
  const notificationOperations = useAuthorized(Resource.Notification);

  const deleteNotificationAllowed =
    notificationOperations.delete || notificationOperations.supervise;

  const [{ data, loading, error }, refetch] = useAxios<Notification>(
    {
      url: `${ADMIN_NOTIFICATION_ROUTE}/${notificationId}`,
      method: 'GET',
    },
    { useCache: false, manual: !notificationId }
  );

  const selectedNotification = data ?? undefined;

  useEffect(() => {
    if ((location as any).state?.prevPath) {
      setPrevPath((location as any)?.state?.prevPath);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLoading = loading || isRequestLoading;

  const handleInsightReview = async (mode: 'approve' | 'decline') => {
    if (selectedNotification?.id) {
      if (mode === 'approve') {
        try {
          setErrorMessage('');
          setIsRequestLoading(true);
          await approvePendingNotification(selectedNotification?.id);
          refetch();
        } catch (error: any) {
          setErrorMessage(error.message);
        } finally {
          refetchPendingNotificationCount();
          setIsRequestLoading(false);
        }
      } else {
        try {
          setErrorMessage('');
          setIsRequestLoading(true);
          await declinePendingNotification(selectedNotification?.id);
          refetch();
        } catch (error: any) {
          setErrorMessage(error.message);
        } finally {
          refetchPendingNotificationCount();
          setIsRequestLoading(false);
        }
      }
    }
  };

  const handleOnDelete = async () => {
    if (selectedNotification?.id) {
      try {
        setIsDeleteDialogOpen(false);
        setIsRequestLoading(true);
        await deleteNotification(selectedNotification?.id);

        if (prevPath) {
          history.push(prevPath);
        } else {
          history.goBack();
        }
      } catch (error: any) {
        setErrorMessage(error.message);
      } finally {
        setIsRequestLoading(false);
      }
    }
  };

  return (
    <DetailPageWrapper>
      <DetailsHeader>
        <OvBackButton label={t('common.actions.back')} />
        <CtaWrapper>
          {selectedNotification?.is_approvement_pending && (
            <StyledOvButton onClick={() => setIsReviewDialogOpen(true)}>
              {t('notifications.actions.reviewInsight')}
            </StyledOvButton>
          )}

          {deleteNotificationAllowed && (
            <StyledOvButton onClick={() => setIsDeleteDialogOpen(true)}>
              {t('notifications.actions.deleteNotification')}
            </StyledOvButton>
          )}
        </CtaWrapper>
      </DetailsHeader>
      <span>{error && <OvErrorMessage message={error.message} />}</span>
      <span>{errorMessage && <OvErrorMessage message={errorMessage} />}</span>

      <DetailPageContentWrapper>
        <TableWrapper>
          <OvTable
            data={NotificationDataUtils.mapNotificationToTableDataFormat(
              selectedNotification
            )}
          />
        </TableWrapper>
        {selectedNotification && selectedNotification.notification_content && (
          <DeviceViewContainer>
            <OvDeviceView>
              <OvNotificationContentDevicePreview
                notificationContentDetails={
                  selectedNotification?.notification_content
                }
              />
            </OvDeviceView>
          </DeviceViewContainer>
        )}
      </DetailPageContentWrapper>

      {isLoading && <OvLoadingIndicator position="fixed" />}

      <OvReviewInsightDialog
        isOpen={isReviewDialogOpen}
        onCancel={() => setIsReviewDialogOpen(false)}
        onSave={handleInsightReview}
        notification={selectedNotification}
      />
      <OvConfirmationDialog
        icon={<StyledDeleteIcon />}
        isOpen={isDeleteDialogOpen}
        onCancel={() => setIsDeleteDialogOpen(false)}
        onConfirm={handleOnDelete}
        description="notifications.dialogs.deleteNotificationConfirmationDialogDescription"
        title="notifications.dialogs.deleteNotificationConfirmationDialogTitle"
      />
    </DetailPageWrapper>
  );
};

export default NotificationDetails;

const DetailPageWrapper = styled.div`
  margin-left: 1rem;
  box-shadow: ${Variables.boxShadow.defaultBox};
  background-color: ${Colours.WHITE};
  padding: 1.5rem;
  border-radius: ${Variables.borderRadius.SMALL};
`;

const DetailsHeader = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  font-weight: bold;
  justify-content: space-between;
`;

const DetailPageContentWrapper = styled.div`
  display: flex;
`;

const TableWrapper = styled.div`
  flex-grow: 1;
  align-self: flex-start;
  margin-left: 12px;
  width: 100%;
  @media (max-width: ${breakpoints.md}) {
    margin-left: 0;
    margin-top: 12px;
  }
`;

const DeviceViewContainer = styled.div`
  min-width: 24rem;
  max-width: 100%;
  height: 41rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem;
`;

const CtaWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledOvButton = styled(OvButton)`
  && {
    display: flex;
    align-items: center;
    text-transform: none;
    padding: 2px 10px;
    padding: 2px 0.625rem;
    border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
    margin-right: 0.5rem;
    background-color: ${Colours.OV_BASE};

    &:last-child {
      margin-right: 0;
    }
  }
`;

const StyledDeleteIcon = styled(DeleteSweepIcon)`
  && {
    box-sizing: content-box;
    padding: 0.5rem;
    background: ${Colours.OV_BASE};
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    text-align: center;
    display: block;
    margin: 1rem auto 0 auto;
    color: ${Colours.OV_SEMI_LIGHT};
  }
`;
