import { startCase } from 'lodash';
import { Notification } from '../../model/dto/notification/notification';
import DateUtils from './date-utils';
import StringUtils from './string-utils';
import { InsightsType } from '../../model/dto/notification/notification-content';
import i18n from '../../../i18n/config';
import { OvTableData } from '../../../components/UI/molecules/OvTable';

export class NotificationDataUtils {
  static mapNotificationToTableDataFormat(
    notification?: Notification
  ): OvTableData {
    return [
      {
        label: 'notifications.fields.notificationContent',
        translateLabel: true,
        value: notification?.notification_content?.id,
        linkUrl: `/notification-contents/${notification?.notification_content?.id}`,
        linkShouldNotRedirect: true,
      },
      {
        label: 'notifications.fields.user',
        translateLabel: true,
        value: notification?.user?.email,
        linkUrl: `/users/${notification?.user_id}`,
        linkShouldNotRedirect: true,
      },
      {
        label: 'notifications.fields.isSeen',
        translateLabel: true,
        value: notification?.is_seen,
        prettifiedValue: StringUtils.getPrettifiedBooleanValue(
          notification?.is_seen
        ),
      },
      {
        label: 'notifications.fields.isVisible',
        translateLabel: true,
        value: notification?.is_visible,
        prettifiedValue: StringUtils.getPrettifiedBooleanValue(
          notification?.is_visible
        ),
      },
      {
        label: 'notifications.fields.isApprovementPending',
        translateLabel: true,
        value: notification?.is_approvement_pending,
        prettifiedValue: StringUtils.getPrettifiedBooleanValue(
          notification?.is_approvement_pending
        ),
      },
      {
        label: 'notifications.fields.isApproved',
        translateLabel: true,
        value: notification?.is_approved,
        prettifiedValue:
          notification?.is_approved === true ||
          notification?.is_approved === false
            ? StringUtils.getPrettifiedBooleanValue(notification?.is_approved)
            : '-',
      },
      {
        label: 'notifications.fields.userResponse',
        translateLabel: true,
        value: notification?.user_response_action_type,
        prettifiedValue: notification?.user_response_action_type ?? '-',
      },
      {
        label: 'notifications.fields.isInsightHelpful',
        translateLabel: true,
        value: notification?.is_insight_helpful,
        prettifiedValue:
          notification?.is_insight_helpful === true ||
          notification?.is_insight_helpful === false
            ? StringUtils.getPrettifiedBooleanValue(
                notification?.is_insight_helpful
              )
            : '-',
      },
      {
        label: 'notifications.fields.feedbackContent',
        translateLabel: true,
        value: notification?.feedback_content,
        prettifiedValue: notification?.feedback_content ?? '-',
      },
      {
        label: 'notifications.fields.seenAt',
        translateLabel: true,
        value: notification?.is_seen_at,
        prettifiedValue: notification?.is_seen_at
          ? DateUtils.formatDate(
              notification?.is_seen_at as unknown as Date,
              'MMMM DD, YYYY, HH:mm:ss'
            )
          : '-',
      },
      {
        label: 'notifications.fields.publishedAt',
        translateLabel: true,
        value: notification?.published_at,
        prettifiedValue: notification?.published_at
          ? DateUtils.formatDate(
              notification?.published_at as unknown as Date,
              'MMMM DD, YYYY, HH:mm:ss'
            )
          : '-',
      },
      {
        label: 'notifications.fields.publishAt',
        translateLabel: true,
        value: notification?.publish_at,
        prettifiedValue: notification?.publish_at
          ? DateUtils.formatDate(
              notification?.publish_at as unknown as Date,
              'MMMM DD, YYYY, HH:mm:ss'
            )
          : '-',
      },
      {
        label: 'notifications.fields.createdAt',
        translateLabel: true,
        value: notification?.created_at,
        prettifiedValue: notification?.created_at
          ? DateUtils.formatDate(
              notification?.created_at as unknown as Date,
              'MMMM DD, YYYY, HH:mm:ss'
            )
          : '-',
      },
      {
        label: 'notifications.fields.updatedAt',
        translateLabel: true,
        value: notification?.updated_at,
        prettifiedValue: notification?.updated_at
          ? DateUtils.formatDate(
              notification?.updated_at as unknown as Date,
              'MMMM DD, YYYY, HH:mm:ss'
            )
          : '-',
      },
    ];
  }

  static mapNotificationToReviewInsightsFormat(
    notification?: Notification
  ): OvTableData {
    const tableData: OvTableData = [
      {
        label: 'notifications.fields.user',
        translateLabel: true,
        value:
          notification?.user?.first_name && notification?.user?.last_name
            ? `${notification?.user?.first_name} ${notification?.user?.last_name} (${notification?.user?.email})`
            : `${notification?.user?.email}`,
        linkShouldNotRedirect: false,
        linkUrl: `/users/${notification?.user.id}`,
      },
      {
        label: 'notificationContents.fields.insightsType',
        translateLabel: true,
        value: notification?.notification_content?.insights_type,
        prettifiedValue: startCase(
          notification?.notification_content?.insights_type
        ),
      },
    ];

    if (
      notification?.notification_content?.insights_type ===
      InsightsType.CYCLE_LENGTH_MODIFICATION
    ) {
      tableData.push({
        label: 'notifications.insightsDescriptions.currentCycleLength',
        translateLabel: true,
        value: notification?.user?.length_of_average_monthly_cycle,
        style: {
          fontWeight: 'bold',
        },
      });
      tableData.push({
        label: 'notifications.insightsDescriptions.proposedCycleLength',
        translateLabel: true,
        value:
          notification?.notification_content?.params
            ?.length_of_average_monthly_cycle,
      });
    }

    return tableData;
  }

  static getInsightDescription(notification?: Notification) {
    if (!notification?.notification_content?.insights_type === null) {
      return;
    }

    switch (notification?.notification_content?.insights_type) {
      case InsightsType.CYCLE_LENGTH_MODIFICATION:
        return i18n.t(
          'notifications.insightsDescriptions.cycleLengthModification',
          {
            proposedCycleLength:
              notification?.notification_content?.params
                ?.length_of_average_monthly_cycle,
            currentCycleLength:
              notification?.user?.length_of_average_monthly_cycle,
          }
        );
    }
  }
}
