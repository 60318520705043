import {
  Field,
  FieldArray,
  FieldProps,
  FormikProvider,
  getIn,
  useFormik,
} from 'formik';
import React, { FC, useEffect, useState } from 'react';
import { CreateNotificationContentRequest } from '../../../common/model/dto/notification/create-notification-content-request';
import * as Yup from 'yup';
import {
  Autocomplete,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Step,
  StepLabel,
  Stepper,
} from '@mui/material';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import breakpoints from '../../../design-system/breakpoints';
import OvTextField from '../atoms/OvTextField';
import Colours from '../../../design-system/colours';
import OvSelect from '../atoms/OvSelect';
import Variables from '../../../design-system/variables';
import {
  InsightsType,
  NotificationActionType,
  NotificationButtonStyle,
  NotificationContentCategory,
  SelectableInsightsTypes,
} from '../../../common/model/dto/notification/notification-content';
import OvImageUpload from './OvImageUpload';
import {
  OOVA_NOTIFICATION_CONTENT_IMAGES,
  OOVA_NOTIFICATION_CONTENT_THUMBNAIL_IMAGES,
} from '../../../api/firebase-storage';
import OvButton from '../atoms/OvButton';
import OvNoContent from './OvNoContent';
import OvDeviceView from './OvDeviceView';
import OvNotificationContentDevicePreview from './OvNotificationContentDevicePreview';
import OvQuillEditor from '../atoms/OvQuillEditor';
import JSONInput from 'react-json-editor-ajrm';
// @ts-ignore
import locale from 'react-json-editor-ajrm/locale/en';

interface OvCreateNotificationsContentDialogProps {
  isOpen: boolean;
  onCancel: () => void;
  onSave: (request: any) => void;
}

const OvCreateNotificationsContentDialog: FC<
  OvCreateNotificationsContentDialogProps
> = ({ isOpen, onCancel, onSave }) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState('');
  const [activeStep, setActiveStep] = useState(0);

  const steps = ['Notification content', 'Action button configurations'];

  const formik = useFormik<CreateNotificationContentRequest>({
    initialValues: {
      title: undefined,
      category: NotificationContentCategory.FROM_OOVA,
      image_url: undefined,
      thumbnail_image_url: undefined,
      actions: [
        {
          cta: '',
          button_style: NotificationButtonStyle.PRIMARY,
          action_url: '',
          type: NotificationActionType.accept,
        },
      ],
      short_description: undefined,
      full_description: undefined,
      disclaimer: undefined,
      tags: undefined,
      insights_type: undefined,
      params: undefined,
      app_version: undefined,
    },
    onSubmit: (request) => {
      onSave(request);
      handleOnCancel();
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().required('Title is required'),
      category: Yup.string().required('Category is required'),
      tags: Yup.array().of(Yup.string()),
      actions: Yup.array().of(
        Yup.object().shape({
          cta: Yup.string().required('CTA is required'),
        })
      ),
      app_version: Yup.string().when('category', {
        is: (category: NotificationContentCategory) =>
          category === NotificationContentCategory.APP_UPDATES,
        then: Yup.string().required(
          'App version is mandatory when creating an app update notification content'
        ),
        otherwise: Yup.string(),
      }),
    }),
  });

  useEffect(() => {
    if (isOpen) {
      formik.resetForm();
      setActiveStep(0);
    }
    // eslint-disable-next-line
  }, [isOpen]);

  useEffect(() => {
    formik.getFieldHelpers('insights_type').setValue(undefined);
    formik.getFieldHelpers('params').setValue(undefined);
    formik.getFieldHelpers('app_version').setValue(undefined);

    // eslint-disable-next-line
  }, [formik.values.category]);

  useEffect(() => {
    if (formik.values.insights_type) {
      switch (formik.values.insights_type) {
        case InsightsType.CYCLE_LENGTH_MODIFICATION:
          const placeholder = { length_of_average_monthly_cycle: 26 };
          formik.getFieldHelpers('params').setValue(placeholder);
      }
    }

    // eslint-disable-next-line
  }, [formik.values.insights_type]);

  const handleSuccessfulImageUpload = ({
    image_path,
    resized_image_path,
  }: {
    image_path: string;
    resized_image_path: string;
  }) => {
    formik.getFieldHelpers('image_url').setValue(image_path);
    formik.getFieldHelpers('thumbnail_image_url').setValue(resized_image_path);
  };

  const handleNextClick = () => {
    setActiveStep((step) => step + 1);
  };

  const handleBackClick = () => {
    setActiveStep((step) => step - 1);
  };

  const handleOnCancel = () => {
    formik.resetForm();
    onCancel();
    setActiveStep(0);
  };

  const handleJsonChange = (value: any) => {
    formik.getFieldHelpers('params').setValue(value.jsObject);
  };

  return (
    <StyledDialog
      open={isOpen}
      onClose={() => {
        formik.resetForm();
        onCancel();
        setActiveStep(0);
      }}
    >
      <FormikProvider value={formik}>
        <StyledDialogTitle>
          {t('notificationContents.dialogs.createNotificationContent')}
        </StyledDialogTitle>

        <StyledStepper activeStep={activeStep}>
          {steps.map((label) => {
            const stepProps: { completed?: boolean } = {};

            return (
              <Step key={label} {...stepProps}>
                <StyledStepLabel>{label}</StyledStepLabel>
              </Step>
            );
          })}
        </StyledStepper>

        <ContentWrapper>
          {activeStep === 0 && (
            <form>
              <StyledDialogContent>
                <StyledTextField
                  type="text"
                  fullWidth
                  autoComplete="off"
                  label={t('notificationContents.fields.title')}
                  error={formik.touched.title && !!formik.errors.title}
                  helperText={
                    formik.errors.title &&
                    formik.touched.title &&
                    formik.errors.title
                  }
                  {...formik.getFieldProps('title')}
                />
                <StyledTextField
                  type="text"
                  fullWidth
                  autoComplete="off"
                  label={t('notificationContents.fields.shortDescription')}
                  error={
                    formik.touched.short_description &&
                    !!formik.errors.short_description
                  }
                  helperText={
                    formik.errors.short_description &&
                    formik.touched.short_description &&
                    formik.errors.short_description
                  }
                  {...formik.getFieldProps('short_description')}
                />
                <Field type="text" name="full_description">
                  {({ field }: FieldProps) => (
                    <StyledOvQuillEditor
                      value={field.value}
                      handleChange={field.onChange(field.name)}
                      placeholder={t(
                        'notificationContents.fields.fullDescription'
                      )}
                    />
                  )}
                </Field>
                <StyledTextField
                  type="text"
                  fullWidth
                  autoComplete="off"
                  label={t('notificationContents.fields.disclaimer')}
                  error={
                    formik.touched.disclaimer && !!formik.errors.disclaimer
                  }
                  helperText={
                    formik.errors.disclaimer &&
                    formik.touched.disclaimer &&
                    formik.errors.disclaimer
                  }
                  {...formik.getFieldProps('disclaimer')}
                />
                <StyledAutocomplete
                  multiple
                  freeSolo
                  options={[]}
                  value={formik.values.tags || []}
                  inputValue={inputValue}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                  }}
                  onChange={(event, newValue) => {
                    formik.setFieldValue('tags', newValue);
                  }}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        label={option as string}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <StyledTextField
                      {...params}
                      label={t('notificationContents.addTags')}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter' && inputValue.trim() !== '') {
                          event.preventDefault();
                          if (
                            formik.values.tags &&
                            !formik?.values?.tags.includes(inputValue.trim())
                          ) {
                            formik.setFieldValue('tags', [
                              ...formik.values.tags,
                              inputValue.trim(),
                            ]);
                          }
                          setInputValue('');
                        }
                      }}
                    />
                  )}
                />
                <StyledLabel>
                  {t('notificationContents.fields.category')}
                </StyledLabel>
                <StyledSelect
                  labelId="health-goal"
                  {...formik.getFieldProps('category')}
                  value={formik.values.category}
                >
                  {Object.values(NotificationContentCategory).map(
                    (category) => (
                      <StyledMenuItem key={category} value={category}>
                        {category}
                      </StyledMenuItem>
                    )
                  )}
                </StyledSelect>

                {formik.values.category ===
                  NotificationContentCategory.INSIGHTS && (
                  <>
                    <StyledLabel>
                      {t('notificationContents.fields.insightsType')}
                    </StyledLabel>
                    <StyledSelect
                      {...formik.getFieldProps('insights_type')}
                      value={formik.values.insights_type}
                      placeholder="Select an insights type"
                      displayEmpty
                    >
                      <StyledMenuItem key={'not-selected'} value={undefined}>
                        {t('common.actions.notSelected')}
                      </StyledMenuItem>
                      {SelectableInsightsTypes.map((insightType) => (
                        <StyledMenuItem key={insightType} value={insightType}>
                          {insightType}
                        </StyledMenuItem>
                      ))}
                    </StyledSelect>

                    {!!formik.values.insights_type && (
                      <JsonContainer>
                        <StyledLabel>
                          {t('notificationContents.fields.params')}
                        </StyledLabel>
                        <JSONInput
                          id="notifications-params-editor"
                          locale={locale}
                          theme="light_mitsuketa_tribute"
                          height="250px"
                          onChange={handleJsonChange}
                          placeholder={formik.values.params}
                        />
                      </JsonContainer>
                    )}
                  </>
                )}

                {formik.values.category ===
                  NotificationContentCategory.APP_UPDATES && (
                  <StyledTextField
                    type="text"
                    fullWidth
                    autoComplete="off"
                    label={t('notificationContents.fields.appVersion')}
                    error={
                      formik.touched.app_version && !!formik.errors.app_version
                    }
                    helperText={
                      formik.errors.app_version &&
                      formik.touched.app_version &&
                      formik.errors.app_version
                    }
                    {...formik.getFieldProps('app_version')}
                  />
                )}

                <OvImageUpload
                  imageBasePath={OOVA_NOTIFICATION_CONTENT_IMAGES}
                  imageUrl={formik.values.image_url}
                  ctaLabel={t('imageUpload.uploadImage')}
                  dialogTitle={t('notificationContents.dialogs.image.title')}
                  hintText={t('notificationContents.dialogs.image.hint')}
                  handleChange={handleSuccessfulImageUpload}
                  resizeOptions={{
                    maxWidth: 150,
                    maxHeight: 150,
                    quailty: 1,
                    resizedImagePath:
                      OOVA_NOTIFICATION_CONTENT_THUMBNAIL_IMAGES,
                  }}
                />
                <StyledDialogActions>
                  <StyledLightOvButton onClick={handleOnCancel}>
                    {t('common.actions.cancel')}
                  </StyledLightOvButton>
                  <StyledOvButton
                    onClick={handleNextClick}
                    disabled={
                      !formik.values.title || !formik.values.short_description
                    }
                  >
                    {t('common.actions.next')}
                  </StyledOvButton>
                </StyledDialogActions>
              </StyledDialogContent>
            </form>
          )}

          {activeStep === 1 && (
            <form onSubmit={formik.handleSubmit}>
              <StyledDialogContent>
                <FieldArray name="actions">
                  {({ push, remove, form }) => (
                    <>
                      {formik.values.actions &&
                      formik.values.actions.length > 0 ? (
                        formik.values.actions.map((action, index) => (
                          <ActionContainer key={index}>
                            <StyledTextField
                              label="CTA"
                              name={`actions.${index}.cta`}
                              value={formik.values?.actions?.[index].cta}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              error={
                                !!getIn(
                                  form.touched,
                                  `actions[${index}].cta`
                                ) &&
                                !!getIn(form.errors, `actions[${index}].cta`)
                              }
                              helperText={
                                getIn(form.touched, `actions[${index}].cta`) &&
                                getIn(form.errors, `actions[${index}].cta`)
                              }
                            />
                            <StyledTextField
                              label="Action URL"
                              name={`actions.${index}.action_url`}
                              value={formik.values.actions?.[index].action_url}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            <StyledLabel>
                              {t(
                                'notificationContents.notificationActions.buttonStyle'
                              )}
                            </StyledLabel>
                            <StyledSelect
                              name={`actions.${index}.button_style`}
                              value={
                                formik.values.actions?.[index].button_style
                              }
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            >
                              {Object.values(NotificationButtonStyle).map(
                                (style) => (
                                  <MenuItem key={style} value={style}>
                                    {style}
                                  </MenuItem>
                                )
                              )}
                            </StyledSelect>
                            <StyledLabel>
                              {t(
                                'notificationContents.notificationActions.type'
                              )}
                            </StyledLabel>
                            <StyledSelect
                              name={`actions.${index}.type`}
                              value={formik.values.actions?.[index].type}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            >
                              {Object.values(NotificationActionType).map(
                                (type) => (
                                  <MenuItem key={type} value={type}>
                                    {type}
                                  </MenuItem>
                                )
                              )}
                            </StyledSelect>
                            <StyledLightOvButton onClick={() => remove(index)}>
                              {t(
                                'notificationContents.dialogs.removeActionButton'
                              )}
                            </StyledLightOvButton>
                          </ActionContainer>
                        ))
                      ) : (
                        <OvNoContent>
                          {t(
                            'notificationContents.dialogs.emptyActionButtonList'
                          )}
                        </OvNoContent>
                      )}
                      <StyledOvButton
                        onClick={() =>
                          push({
                            cta: '',
                            button_style: NotificationButtonStyle.PRIMARY,
                            action_url: '',
                            type: NotificationActionType.accept,
                          })
                        }
                      >
                        {t('notificationContents.dialogs.addActionButton')}
                      </StyledOvButton>
                    </>
                  )}
                </FieldArray>
                <StyledDialogActions>
                  <StyledLightOvButton onClick={handleOnCancel}>
                    {t('common.actions.cancel')}
                  </StyledLightOvButton>
                  <StyledLightOvButton onClick={handleBackClick}>
                    {t('common.actions.back')}
                  </StyledLightOvButton>
                  <StyledOvButton
                    type="submit"
                    disabled={formik.values.actions?.length === 0}
                  >
                    {t('common.actions.create')}
                  </StyledOvButton>
                </StyledDialogActions>
              </StyledDialogContent>
            </form>
          )}

          <StyledDialogContent>
            <DeviceViewContainer>
              <OvDeviceView>
                <OvNotificationContentDevicePreview
                  notificationContentDetails={formik.values}
                />
              </OvDeviceView>
            </DeviceViewContainer>
          </StyledDialogContent>
        </ContentWrapper>
      </FormikProvider>
    </StyledDialog>
  );
};

export default OvCreateNotificationsContentDialog;

const StyledDialog = styled(Dialog)`
  && {
    .MuiDialog-paperScrollPaper {
      max-width: 100%;
      background-color: ${Colours.OV_WHITE};
    }
  }
`;

const StyledDialogTitle = styled(DialogTitle)`
  && {
    text-align: center;
    font-weight: bold;
    text-transform: none;
  }
`;

const StyledDialogContent = styled(DialogContent)`
  && {
    width: 100%;
    min-width: 31.25rem !important;
    padding: 0.5rem 1.5rem !important;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 0.75rem;

    @media (min-width: ${breakpoints.sm}) {
      width: 34rem;
    }
  }
`;

const StyledTextField = styled(OvTextField)`
  && {
    flex: 1 0 100%;

    .MuiFormHelperText-root.Mui-error {
      background: ${Colours.OV_WHITE};
      margin: 0;
      padding: 0.25rem 0.75rem;
    }
    .MuiInputLabel-formControl {
      top: -0.2rem;
      opacity: 0.6;
    }
  }
`;

const StyledSelect = styled(OvSelect)`
  && {
    max-width: none;
    height: 3rem;

    &.MuiInputBase-root {
      font-size: 0.875rem;
      border-color: ${Colours.OV_LIGHT_GRAY};
      width: 100%;

      .MuiOutlinedInput-notchedOutline {
        border-color: ${Colours.OV_BASE};
      }
    }
  }
`;

const StyledLabel = styled.label`
  font-size: ${Variables.fontSizes.MEDIUM};
  margin-right: 0.5rem;
`;

const StyledMenuItem = styled(MenuItem)`
  && {
    &.MuiButtonBase-root {
      display: flex;
      justify-content: flex-start;
      padding: 0.375rem 1rem;
      font-size: ${Variables.fontSizes.MEDIUM};
    }
  }
`;

const StyledAutocomplete = styled(Autocomplete)`
  && {
    .MuiFormControl-root,
    .MuiInputLabel-formControl {
      font-size: 0.875rem;
    }

    .MuiInputBase-formControl {
      font-size: 0.75rem;
      background: ${Colours.WHITE};
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 0;
    }
  }
`;

const ActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid ${Colours.OV_BASE};
  padding-bottom: 1rem;
`;

const StyledLightOvButton = styled(OvButton)`
  && {
    border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
    padding: 0 1rem;
    background-color: ${Colours.OV_WHITE};
    border: 1px solid rgba(1, 39, 70, 0.5);
    color: ${Colours.OV_BASE};
    font-weight: bold;
    text-transform: none;
    max-width: fit-content;
    &:hover {
      border: 1px solid ${Colours.OV_BASE};
      background-color: rgba(1, 39, 70, 0.04);
    }
  }
`;

const StyledOvButton = styled(OvButton)`
  && {
    padding: 0 1rem;
    border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
    margin-right: 0.75rem;
    transition: none;
    font-weight: bold;
    text-transform: none;
    margin-left: 1rem !important;
    max-width: fit-content;

    &:last-child {
      margin-right: 0;
    }
  }
`;

const StyledStepper = styled(Stepper)`
  && {
    padding: 1rem;
  }
`;

const StyledStepLabel = styled(StepLabel)`
  && {
    .Mui-active {
      color: ${Colours.OV_BASE};
    }

    .Mui-completed {
      color: ${Colours.OV_GREEN};
    }
  }
`;

const StyledDialogActions = styled(DialogActions)`
  && {
    margin-top: 2rem;
    padding: 0 0 0.5rem 1.5rem;
  }
`;

const DeviceViewContainer = styled.div`
  min-width: 23rem;
  max-width: 100%;
  height: 41rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem;
`;

const ContentWrapper = styled.div`
  display: flex;
`;

const StyledOvQuillEditor = styled(OvQuillEditor)`
  margin-bottom: 0.75rem;
`;

const JsonContainer = styled.div`
  margin-bottom: 1.5rem;
`;
