import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Step,
  StepLabel,
  Stepper,
  Switch,
  FormControlLabel,
  Autocomplete,
  TextField,
} from '@mui/material';
import React, { FC, useEffect, useState, useMemo } from 'react';
import { LotType, lotTypeConfig } from '../../../common/model/type/lot.type';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import OvSelect from '../atoms/OvSelect';
import Variables from '../../../design-system/variables';
import OvButton from '../atoms/OvButton';
import { useFormik } from 'formik';
import { CreateScanRequest } from '../../../common/model/dto/create-scan-request';
import * as Yup from 'yup';
import breakpoints from '../../../design-system/breakpoints';
import OvTextField from '../atoms/OvTextField';
import Colours from '../../../design-system/colours';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { Lot } from '../../../common/model/dto/lot';
import { getLots } from '../../../redux/thunks/lots.thunk';
import OvLoadingIndicator from '../atoms/OvLoadingIndicator';
import { clearLotsList } from '../../../redux/reducers/lots.slice';
import OvDatePicker from '../molecules/OvDatePicker';
import DateUtils from '../../../common/utils/services/date-utils';
import OvUserTypeahead from '../molecules/OvUserTypeahead';
import { UserInfo } from '../../../common/model/dto/user-info';
import UserService from '../../../services/user.service';
import OvImageUpload from './OvImageUpload';
import JSONInput from 'react-json-editor-ajrm';
// @ts-ignore
import locale from 'react-json-editor-ajrm/locale/en';
import { TOOLKIT_VERSIONS } from '../../../common/model/dto/scan-sequences/scan-type.enum';

const OvCreateScanDialog: FC<{
  isOpen: boolean;
  onCancel: () => void;
  onSave: (request: any) => void;
  selectedUserId: string;
  selectedDay: string;
  showDaySelector?: boolean;
  showUserSelector?: boolean;
  showImageUploader?: boolean;
}> = ({
  isOpen,
  onCancel,
  selectedUserId,
  selectedDay,
  showDaySelector,
  showUserSelector,
  showImageUploader,
  onSave,
}) => {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);
  const [scanDate, setScanDate] = useState<string | null>(selectedDay || '');
  const [selectedUser, setSelectedUser] = useState<UserInfo | null>(null);
  const [useImageUpload, setUseImageUpload] = useState<boolean>(false);

  const lotTypeList = useMemo(() => lotTypeConfig(t), [t]);

  const steps = [
    t('scans.dialogs.steps.chooseScanType'),
    t('scans.dialogs.steps.insertValues'),
  ];

  const initialFormValues: CreateScanRequest = {
    user_id: showUserSelector ? '' : selectedUserId || '',
    day: selectedDay || '',
    lr_e3g: undefined,
    lh: undefined,
    pg: undefined,
    lot_id: 'not-selected',
    input_image_url: '',
    toolkit_version: '',
    toolkit_algo_parameters: {},
  };

  const [selectedValue, setSelectedValue] = useState<LotType>(
    lotTypeList[0].value
  );
  const dispatch = useAppDispatch();
  const lotList: Lot[] = useAppSelector((state) => state.lots.lotList);
  const isLotLoading: boolean = useAppSelector((state) => state.lots.loading);
  const isScanLoading: boolean = useAppSelector(
    (state) => state.scans.isLoading
  );
  const isLoading = isLotLoading || isScanLoading;

  const validationSchema = useMemo(() => {
    const schemaFields: Record<string, any> = {
      user_id: Yup.string().when([], {
        is: () => showUserSelector,
        then: Yup.string().required(t('scans.userRequired')),
        otherwise: Yup.string(),
      }),
      day: !selectedDay
        ? Yup.string().required(t('scans.dayRequired'))
        : Yup.string().notRequired(),
      lot_id: Yup.string().when([], {
        is: () => useImageUpload,
        then: Yup.string()
          .required(t('scans.lotRequired'))
          .notOneOf(['not-selected'], t('scans.lotRequired')),
        otherwise: Yup.string().notRequired(),
      }),
      lh: Yup.number(),
      pg: Yup.number(),
      lr_e3g: Yup.number(),
      input_image_url: Yup.string().when('useImageUpload', {
        is: true,
        then: Yup.string().required(t('scans.imageRequired')),
        otherwise: Yup.string().notRequired(),
      }),
      toolkit_version: Yup.string().when('useImageUpload', {
        is: true,
        then: Yup.string().required(t('scans.toolkitVersionRequired')),
        otherwise: Yup.string().notRequired(),
      }),
      toolkit_algo_parameters: Yup.object(),
    };

    if (!useImageUpload) {
      if (
        selectedValue === LotType.CTRL_PG_LH ||
        selectedValue === LotType.CTRL_LH_PG
      ) {
        schemaFields.lh = Yup.number().min(0).required(t('scans.lhRequired'));
        schemaFields.pg = Yup.number().min(0).required(t('scans.pgRequired'));
        schemaFields.lr_e3g = Yup.number().notRequired();
      } else if (
        selectedValue === LotType.E3G ||
        selectedValue === LotType.LR_E3G
      ) {
        schemaFields.lr_e3g = Yup.number()
          .min(0)
          .required(t('scans.e3gRequired'));
        schemaFields.lh = Yup.number().notRequired();
        schemaFields.pg = Yup.number().notRequired();
      }
    }

    return Yup.object().shape(schemaFields);
  }, [selectedValue, useImageUpload, showUserSelector, selectedDay, t]);

  const formik = useFormik<CreateScanRequest>({
    initialValues: initialFormValues,
    onSubmit: (createScanRequest) => {
      onSave({ ...createScanRequest, day: scanDate || '' });
    },
    validationSchema: validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,
  });

  useEffect(() => {
    if (isOpen) {
      formik.resetForm({ values: initialFormValues });
      setActiveStep(0);
      setSelectedValue(lotTypeList[0].value);
      setUseImageUpload(false);
      setScanDate(selectedDay || '');
      setSelectedUser(null);
    }
    // eslint-disable-next-line
  }, [isOpen]);

  const selectUser = (user: UserInfo | any) => {
    if (user && user?.id) {
      setSelectedUser(user);
      formik.setFieldValue('user_id', user.id);
    }
  };

  const fetchUsers = (searchText: string) => {
    return UserService.getUserTypeahead(searchText);
  };

  const handleChange = (event: any, newValue?: any) => {
    const value = event?.target ? event.target.value : newValue;
    setSelectedValue(value);
  };

  const handleNextClick = async () => {
    if (activeStep === steps.length - 1) {
      // Last step, submit the form
      formik.handleSubmit();
    } else {
      setActiveStep(activeStep + 1);
      dispatch(getLots({ limit: 0, lotType: selectedValue }));
    }
  };

  const handleBackClick = () => {
    formik.resetForm({ values: initialFormValues });
    dispatch(clearLotsList());
    setActiveStep(0);
  };

  const handleDateSelection = (date: Date) => {
    const formattedDate = DateUtils.getDbDateTag(date);
    setScanDate(formattedDate);
    formik.setFieldValue('day', formattedDate);
  };

  const handleCancel = () => {
    onCancel?.();
  };

  const handleToolkitAlgoParamsChange = (value: any) => {
    if (value.jsObject !== undefined) {
      formik.setFieldValue('toolkit_algo_parameters', value.jsObject);
    }
  };

  return (
    <Dialog open={isOpen} onClose={handleCancel}>
      <StyledDialogTitle>{t('scans.createScan')}</StyledDialogTitle>

      <StyledStepper activeStep={activeStep}>
        {steps.map((label) => {
          const stepProps: { completed?: boolean } = {};

          return (
            <Step key={label} {...stepProps}>
              <StyledStepLabel>{label}</StyledStepLabel>
            </Step>
          );
        })}
      </StyledStepper>

      {activeStep === 0 && (
        <form>
          <StyledDialogContent>
            <StyledOvSelect value={selectedValue} onChange={handleChange}>
              {lotTypeList &&
                lotTypeList.map((type) => (
                  <StyledMenuItem key={type.value} value={type.value}>
                    {type.label}
                  </StyledMenuItem>
                ))}
            </StyledOvSelect>
            <StyledDialogActions>
              <StyledLightOvButton onClick={handleCancel}>
                {t('common.actions.cancel')}
              </StyledLightOvButton>
              <StyledOvButton onClick={handleNextClick}>
                {t('common.actions.next')}
              </StyledOvButton>
            </StyledDialogActions>
          </StyledDialogContent>
        </form>
      )}

      {activeStep === 1 && (
        <StyledDialogContent>
          {showUserSelector && (
            <>
              <StyledHeader>{t('scans.selectUser')}</StyledHeader>
              <StyledOvUserTypeahead
                placeholder={t('common.actions.search')}
                onUserSelect={selectUser}
                fetchUsers={fetchUsers}
              />
              {formik.errors.user_id && (
                <ErrorText>{formik.errors.user_id}</ErrorText>
              )}
              {selectedUser &&
                `${selectedUser?.first_name} ${selectedUser?.last_name} (${selectedUser?.email})`}
            </>
          )}
          {showDaySelector && (
            <>
              <StyledHeader>{t('scans.selectDay')}</StyledHeader>
              <StyledDatePicker
                views={['year', 'month', 'day']}
                value={scanDate ? new Date(scanDate) : new Date()}
                onChange={(date) => handleDateSelection(date as Date)}
              />
            </>
          )}

          {showImageUploader && (
            <FormControlLabel
              control={
                <Switch
                  checked={useImageUpload}
                  onChange={(e) => setUseImageUpload(e.target.checked)}
                  color="primary"
                />
              }
              label={t('scans.useImageUpload')}
            />
          )}
          {useImageUpload ? (
            <>
              {lotList && lotList.length > 0 && (
                <>
                  <StyledHeader>{t('scans.selectLot')}</StyledHeader>
                  <StyledOvSelect
                    error={formik.touched.lot_id && !!formik.errors.lot_id}
                    {...formik.getFieldProps('lot_id')}
                  >
                    <StyledMenuItem value={'not-selected'}>
                      <em>{t('common.actions.notSelected')}</em>
                    </StyledMenuItem>
                    {lotList.map((lot) => (
                      <StyledMenuItem key={lot.id} value={lot.id}>
                        {`${lot?.type} - ${
                          lot?.name ?? lot?.document_id
                            ? lot?.document_id
                            : lot.id
                        }`}
                      </StyledMenuItem>
                    ))}
                  </StyledOvSelect>
                  {formik.errors.lot_id && (
                    <ErrorText>{formik.errors.lot_id}</ErrorText>
                  )}
                </>
              )}
              <OvImageUpload
                imageBasePath="/scan_images/"
                title={t('scans.uploadImageTitle')}
                ctaLabel={t('scans.uploadImageCta')}
                hintText={t('scans.uploadImageHint')}
                dialogTitle={t('scans.uploadImageDialogTitle')}
                handleChange={(updatedProperties: { image_path: string }) => {
                  formik.setFieldValue(
                    'input_image_url',
                    updatedProperties.image_path
                  );
                }}
              />
              {formik.errors.input_image_url && (
                <ErrorText>{formik.errors.input_image_url}</ErrorText>
              )}

              <StyledHeader>
                {t('common.scanDetails.results.toolkitVersion')}
              </StyledHeader>
              <Autocomplete
                freeSolo
                options={TOOLKIT_VERSIONS}
                value={formik.values.toolkit_version}
                onChange={(event, newValue) =>
                  formik.setFieldValue('toolkit_version', newValue || '')
                }
                onInputChange={(event, newInputValue) =>
                  formik.setFieldValue('toolkit_version', newInputValue)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('common.scanDetails.results.toolkitVersion')}
                    size="small"
                    error={
                      formik.touched.toolkit_version &&
                      !!formik.errors.toolkit_version
                    }
                    helperText={
                      formik.touched.toolkit_version &&
                      formik.errors.toolkit_version
                    }
                  />
                )}
              />

              <StyledHeader>{t('lots.toolkitAlgoParameters')}</StyledHeader>
              <JSONInput
                id="toolkit_algo_parameters_editor"
                placeholder={formik.values.toolkit_algo_parameters || {}}
                locale={locale}
                theme="light_mitsuketa_tribute"
                height="250px"
                onChange={handleToolkitAlgoParamsChange}
              />
            </>
          ) : (
            <>
              <StyledHeader>{t('scans.setValue')}</StyledHeader>
              {selectedValue === LotType.CTRL_PG_LH ||
              selectedValue === LotType.CTRL_LH_PG ? (
                <>
                  <StyledTextField
                    type="number"
                    fullWidth
                    autoComplete="off"
                    label="LH"
                    disabled={isLoading}
                    error={formik.touched.lh && !!formik.errors.lh}
                    helperText={
                      formik.errors.lh && formik.touched.lh && formik.errors.lh
                    }
                    {...formik.getFieldProps('lh')}
                  />
                  <StyledTextField
                    type="number"
                    fullWidth
                    autoComplete="off"
                    label="PG"
                    disabled={isLoading}
                    error={formik.touched.pg && !!formik.errors.pg}
                    helperText={
                      formik.errors.pg && formik.touched.pg && formik.errors.pg
                    }
                    {...formik.getFieldProps('pg')}
                  />
                </>
              ) : (
                <>
                  <StyledTextField
                    type="number"
                    fullWidth
                    autoComplete="off"
                    label="E3G"
                    disabled={isLoading}
                    error={formik.touched.lr_e3g && !!formik.errors.lr_e3g}
                    helperText={
                      formik.errors.lr_e3g &&
                      formik.touched.lr_e3g &&
                      formik.errors.lr_e3g
                    }
                    {...formik.getFieldProps('lr_e3g')}
                  />
                </>
              )}
            </>
          )}
          <StyledDialogActions>
            <StyledLightOvButton onClick={handleBackClick}>
              {t('common.actions.back')}
            </StyledLightOvButton>
            <StyledOvButton
              disabled={isLoading}
              onClick={() => {
                formik.handleSubmit();
              }}
            >
              {t('scans.createScan')}
            </StyledOvButton>
          </StyledDialogActions>
        </StyledDialogContent>
      )}
      {isLoading && <OvLoadingIndicator position="fixed" />}
    </Dialog>
  );
};

export default OvCreateScanDialog;

const StyledDialogTitle = styled(DialogTitle)`
  && {
    text-align: center;
    font-weight: bold;
    text-transform: none;
  }
`;

const StyledDialogContent = styled(DialogContent)`
  && {
    width: 100%;
    min-width: 500px !important;
    padding: 0.5rem 1.5rem !important;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;

    @media (min-width: ${breakpoints.sm}) {
      width: 34rem;
    }
  }
`;

const StyledOvSelect = styled(OvSelect)`
  && {
    width: 100%;
    align-self: center;
    max-width: 100%;
    margin-bottom: 0.75rem;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  && {
    &.MuiButtonBase-root {
      display: flex;
      justify-content: flex-start;
      padding: 0.375rem 1rem;
      font-size: ${Variables.fontSizes.MEDIUM};
    }
  }
`;

const StyledOvButton = styled(OvButton)`
  && {
    padding: 0 1rem;
    border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
    transition: none;
    font-weight: bold;
    text-transform: none;
    margin-left: 1rem !important;
    align-self: flex-end;
  }
`;

const StyledTextField = styled(OvTextField)`
  && {
    flex: 1 0 100%;

    .MuiFormHelperText-root.Mui-error {
      background: white;
      margin: 0;
      padding: 0.25rem 0.75rem;
    }
    .MuiInputLabel-formControl {
      top: -0.2rem;
      opacity: 0.6;
    }
  }
`;

const StyledStepper = styled(Stepper)`
  && {
    padding: 1rem 3rem;
  }
`;

const StyledStepLabel = styled(StepLabel)`
  && {
    .Mui-active {
      color: ${Colours.OV_BASE};
    }

    .Mui-completed {
      color: ${Colours.OV_GREEN};
    }
  }
`;

const StyledDialogActions = styled(DialogActions)`
  && {
    margin-top: 2rem;
    padding: 0 1.5rem 1.5rem 1.5rem;
  }
`;

const StyledLightOvButton = styled(OvButton)`
  && {
    border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
    padding: 0 1rem;
    background-color: ${Colours.OV_WHITE};
    border: 1px solid rgba(1, 39, 70, 0.5);
    color: ${Colours.OV_BASE};
    font-weight: bold;
    text-transform: none;
    &:hover {
      border: 1px solid ${Colours.OV_BASE};
      background-color: rgba(1, 39, 70, 0.04);
    }
  }
`;

const StyledHeader = styled.h5`
  color: ${Colours.OV_BASE};
  padding-bottom: 0;
  margin: 0;
`;

const StyledDatePicker = styled(OvDatePicker)`
  && {
    width: 100%;
    margin-bottom: 0.75rem;

    .MuiInputBase-root {
      padding: 0.375rem 1rem;
      font-size: ${Variables.fontSizes.MEDIUM};
      color: ${Colours.OV_BASE};
    }

    .MuiOutlinedInput-root {
      fieldset {
        border-color: ${Colours.OV_BASE};
      }
      &:hover fieldset {
        border-color: ${Colours.OV_DARK_BLUE};
      }
      &.Mui-focused fieldset {
        border-color: ${Colours.OV_DARK_BLUE};
      }
    }

    .MuiFormLabel-root {
      color: ${Colours.OV_BASE};
      font-size: ${Variables.fontSizes.SMALL};
      margin-left: 1rem;
    }

    .MuiInputLabel-shrink {
      color: ${Colours.OV_BASE};
      font-weight: bold;
    }
  }
`;

const StyledOvUserTypeahead = styled(OvUserTypeahead)`
  && {
    max-width: 100%;
    min-width: 15rem;
    width: 100%;
    border-radius: ${Variables.borderRadius.SMALL};
    font-size: 0.9rem;

    & input {
      padding: 0.4rem !important;
      background: ${Colours.WHITE};
    }
  }
`;

const ErrorText = styled.div`
  color: ${Colours.OV_RED};
  font-size: ${Variables.fontSizes.SMALL};
  margin-top: 0.5rem;
`;
