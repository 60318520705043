import useAxios from 'axios-hooks';
import React, { createContext, ReactNode, FC } from 'react';
import { ADMIN_NOTIFICATION_ROUTE } from '../api/backend';
import { useAppSelector } from '../redux/hooks';

interface NotificationContextProps {
  pendingNotificationCount: number;
  refetchPendingNotificationCount: () => void;
}

export const NotificationContext = createContext<NotificationContextProps>({
  pendingNotificationCount: 0,
  refetchPendingNotificationCount: () => {},
});

interface NotificationProviderProps {
  children: ReactNode;
}

export const NotificationProvider: FC<NotificationProviderProps> = ({
  children,
}) => {
  // TODO: Remove this redux dependency when current Oova360 will be only an Admin tool
  const selectedRole = useAppSelector((state) => state.user.selectedRole);

  const [{ data }, refetch] = useAxios<number>(
    {
      url: `/${ADMIN_NOTIFICATION_ROUTE}/pending-notification-count`,
      method: 'GET',
    },

    { manual: selectedRole !== 'Admin' }
  );

  const notificationCount = data || 0;

  return (
    <NotificationContext.Provider
      value={{
        pendingNotificationCount: notificationCount,
        refetchPendingNotificationCount: refetch,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
