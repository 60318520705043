import { UserInfo } from '../../model/dto/user-info';
import { OvTableData } from '../../../components/UI/molecules/OvTable';
import DateUtils from './date-utils';
import ConversionUtils from './conversion-utils';
import { isNumber } from 'lodash';
import { TableRowEditWidgetType } from '../../model/ui/table-row-edit-widget.type';
import { UserInfoFields } from '../../../firebase/document-field.enums';
import i18n from 'i18next';
import { Constants } from '../../constants';
import { CycleInfo } from '../../model/dto/cycle-info';
import Colours from '../../../design-system/colours';
import { HealthGoalType } from '../../model/dto/health-goal-type.enum';
import { UserSpecificBaselines } from '../../model/dto/user-specific-baselines';
import StringUtils from './string-utils';

export class UserInfoDataUtils {
  static mapUserAccountDetailsToTableDataFormat(
    userInfo?: UserInfo,
    roles?: string[]
  ): OvTableData {
    return [
      {
        label: 'common.userFields.roles',
        translateLabel: true,
        value: userInfo?.roles,
        editOptions: {
          propertyName: UserInfoFields.roles,
          widgetType: TableRowEditWidgetType.NON_NULLABLE_MULTI_SELECT,
          options: roles
            ? roles.map((role) => ({ label: role, value: role }))
            : [],
        },
      },
      {
        label: 'common.userFields.isGuidanceModeEnabled',
        value: userInfo?.is_guidance_mode_enabled,
        prettifiedValue: userInfo?.is_guidance_mode_enabled
          ? i18n.t('common.actions.yes')
          : i18n.t('common.actions.no'),
        translateLabel: true,
        editOptions: {
          propertyName: UserInfoFields.is_guidance_mode_enabled,
          widgetType: TableRowEditWidgetType.BOOLEAN,
        },
      },
      {
        label: 'common.userFields.logPreferences',
        value: userInfo?.symptoms,
        translateLabel: true,
      },
      {
        label: 'common.userFields.registrationDevice',
        value: userInfo?.registration_device,
        translateLabel: true,
      },
      {
        label: 'common.userFields.registrationAppVersion',
        value: userInfo?.registration_app_version,
        translateLabel: true,
      },
      {
        label: 'common.userFields.regDate',
        value: DateUtils.getDateStrFromTimestamp(
          userInfo?.registration_date,
          Constants.DATE_DISPLAY_FORMAT
        ),
        translateLabel: true,
      },
      {
        label: 'common.userFields.registrationTimezone',
        value: userInfo?.registration_time_zone_abbreviation,
        translateLabel: true,
      },
      {
        label: 'common.userFields.registrationTimezoneLocation',
        value: userInfo?.registration_time_zone_identifier,
        translateLabel: true,
      },
      {
        label: 'common.userFields.isFlaggedForDeactivation',
        value: userInfo?.is_flagged_for_deactivation,
        prettifiedValue: StringUtils.getPrettifiedBooleanValue(
          userInfo?.is_flagged_for_deactivation
        ),
        translateLabel: true,
        editOptions: {
          propertyName: UserInfoFields.is_flagged_for_deactivation,
          widgetType: TableRowEditWidgetType.BOOLEAN,
        },
      },
      {
        label: 'common.userFields.flaggedForDeactivationAt',
        value: userInfo?.flagged_for_deactivation_at,
        prettifiedValue: DateUtils.getDateStrFromTimestamp(
          userInfo?.flagged_for_deactivation_at,
          Constants.DATE_DISPLAY_FORMAT
        ),
        translateLabel: true,
      },
    ];
  }

  static mapGeneralUserInfoDataToTableFormat(userInfo?: UserInfo): OvTableData {
    return [
      {
        label: 'common.userFields.firstName',
        value: userInfo?.first_name,
        translateLabel: true,
        editOptions: {
          widgetType: TableRowEditWidgetType.TEXT,
          propertyName: UserInfoFields.first_name,
          placeholder: i18n.t('common.userFields.firstName'),
        },
      },
      {
        label: 'common.userFields.lastName',
        value: userInfo?.last_name,
        translateLabel: true,
        editOptions: {
          widgetType: TableRowEditWidgetType.TEXT,
          propertyName: UserInfoFields.last_name,
          placeholder: i18n.t('common.userFields.lastName'),
        },
      },
      {
        label: 'common.userFields.email',
        value: userInfo?.email,
        translateLabel: true,
        editOptions: {
          widgetType: TableRowEditWidgetType.TEXT,
          propertyName: UserInfoFields.email,
          placeholder: i18n.t('common.userFields.email'),
        },
      },
      {
        label: 'common.userFields.dateOfBirth',
        value: userInfo?.day_of_birth,
        prettifiedValue: DateUtils.getDateStrFromTimestamp(
          userInfo?.day_of_birth,
          Constants.DATE_DISPLAY_FORMAT
        ),
        translateLabel: true,
        editOptions: {
          widgetType: TableRowEditWidgetType.DATE,
          propertyName: UserInfoFields.day_of_birth,
        },
      },

      {
        label: 'common.userFields.height',
        value: userInfo?.height_array,
        prettifiedValue: userInfo?.height_array?.length
          ? `${userInfo?.height_array[0]}' ${
              userInfo?.height_array[1]
            }'' | ${ConversionUtils.heightInCm(
              userInfo?.height_array[0],
              userInfo?.height_array[1]
            ).toFixed(2)} ${i18n.t('common.measurements.cm')}`
          : '',
        translateLabel: true,
        editOptions: {
          widgetType: TableRowEditWidgetType.HEIGHT,
          propertyName: UserInfoFields.height_array,
        },
      },
      {
        label: 'common.userFields.weight',
        value: userInfo?.weight,
        prettifiedValue: isNumber(userInfo?.weight)
          ? `${userInfo?.weight} ${i18n.t('common.measurements.lbs')} | ${
              userInfo?.weight &&
              ConversionUtils.lbsToKg(userInfo?.weight).toFixed(2)
            } ${i18n.t('common.measurements.kg')}`
          : userInfo?.weight,
        translateLabel: true,
        editOptions: {
          widgetType: TableRowEditWidgetType.NUMBER,
          min: 0,
          placeholder: `${i18n.t('common.userFields.weight')} ${i18n.t(
            'common.measurements.lbs'
          )}`,
          propertyName: UserInfoFields.weight,
        },
      },
      {
        label: 'common.userFields.race',
        value: userInfo?.race,
        translateLabel: true,
        editOptions: {
          widgetType: TableRowEditWidgetType.SELECT,
          propertyName: UserInfoFields.race,
          options: [
            {
              label: i18n.t(
                'common.userFields.raceValues.americanIndianOrAlaskaNative'
              ),
              value: 'American Indian or Alaska Native',
            },
            {
              label: i18n.t('common.userFields.raceValues.asian'),
              value: 'Asian',
            },
            {
              label: i18n.t(
                'common.userFields.raceValues.blackOrAfricanAmerican'
              ),
              value: 'Black or African-American',
            },
            {
              label: i18n.t('common.userFields.raceValues.nativeHawaiian'),
              value: 'Native Hawaiian or Other Pacific Islands',
            },
            {
              label: i18n.t('common.userFields.raceValues.white'),
              value: 'White',
            },
            {
              label: i18n.t('common.userFields.raceValues.moreThanOne'),
              value: 'More than once race',
            },
            {
              label: i18n.t('common.userFields.raceValues.unknown'),
              value: 'Unknown',
            },
          ],
        },
      },
      {
        label: 'common.userFields.ethnicity',
        value: userInfo?.ethnicity,
        translateLabel: true,
        editOptions: {
          widgetType: TableRowEditWidgetType.SELECT,
          propertyName: UserInfoFields.ethnicity,
          options: [
            {
              label: i18n.t(
                'common.userFields.ethnicityValues.hispanicOrLatino'
              ),
              value: 'Hispanic or Latino',
            },
            {
              label: i18n.t(
                'common.userFields.ethnicityValues.notHispanicOrLatino'
              ),
              value: 'Not Hispanic or Latino',
            },
            {
              label: i18n.t('common.userFields.ethnicityValues.unknown'),
              value: 'Unknown',
            },
          ],
        },
      },
    ];
  }

  static mapUserHealthInfoToTableDataFormat(
    userInfo?: UserInfo,
    editable: boolean = false
  ): OvTableData {
    return [
      {
        label: 'common.userFields.personalMaxLh',
        value: userInfo?.personal_max_lh,
        prettifiedValue: isNumber(userInfo?.personal_max_lh)
          ? `${userInfo?.personal_max_lh} ${Constants.LH_UNIT_OF_MEASURE}`
          : null,
        translateLabel: true,
        editOptions: editable
          ? {
              propertyName: UserInfoFields.personal_max_lh,
              widgetType: TableRowEditWidgetType.NUMBER,
              min: 0.0,
              max: Constants.LH_MAX,
              step: 0.01,
              canReset: true,
            }
          : undefined,
      },
      {
        label: 'common.userFields.personalMaxPg',
        value: userInfo?.personal_max_pg,
        prettifiedValue: isNumber(userInfo?.personal_max_pg)
          ? `${userInfo?.personal_max_pg} ${Constants.PG_UNIT_OF_MEASURE}`
          : null,
        translateLabel: true,
        editOptions: editable
          ? {
              propertyName: UserInfoFields.personal_max_pg,
              widgetType: TableRowEditWidgetType.NUMBER,
              min: 0.0,
              max: Constants.PG_MAX,
              step: 0.01,
              canReset: true,
            }
          : undefined,
      },
      {
        label: 'common.userFields.healthGoal',
        value: userInfo?.health_goal,
        translateLabel: true,
        editOptions: editable
          ? {
              options: [
                {
                  label: i18n.t('common.userFields.goals.tryToConceive'),
                  value: HealthGoalType.TRYING_TO_CONCEIVE,
                },
                {
                  label: i18n.t(
                    'common.userFields.goals.perimenopauseTracking'
                  ),
                  value: HealthGoalType.PERIMENOPAUSE_TRACKING,
                },
                {
                  label: i18n.t(
                    'common.userFields.goals.generalHealthMonitoring'
                  ),
                  value: HealthGoalType.GENERAL_HEALTH_MONITORING,
                },
              ],
              propertyName: UserInfoFields.health_goal,
              widgetType: TableRowEditWidgetType.SELECT,
            }
          : undefined,
      },
      {
        label: 'common.userFields.reproductiveDisorder',
        value: userInfo?.known_reproductive_disorder,
        translateLabel: true,
        editOptions: editable
          ? {
              options: [
                {
                  label: i18n.t(
                    'common.userFields.reproductiveDisorderValues.none'
                  ),
                  value: 'None',
                },
                {
                  label: i18n.t(
                    'common.userFields.reproductiveDisorderValues.endometriosis'
                  ),
                  value: 'Endometriosis',
                },
                {
                  label: i18n.t(
                    'common.userFields.reproductiveDisorderValues.fibroids'
                  ),
                  value: 'Fibroids',
                },
                {
                  label: i18n.t(
                    'common.userFields.reproductiveDisorderValues.hormonalImbalance'
                  ),
                  value: 'Hormonal Imbalance',
                },
                {
                  label: i18n.t(
                    'common.userFields.reproductiveDisorderValues.infertility'
                  ),
                  value: 'Infertility',
                },
                {
                  label: i18n.t(
                    'common.userFields.reproductiveDisorderValues.pelvicInflammatoryDisease'
                  ),
                  value: 'Pelvic Inflammatory Disease (PID)',
                },
                {
                  label: i18n.t(
                    'common.userFields.reproductiveDisorderValues.perimenopause'
                  ),
                  value: 'Perimenopause',
                },
                {
                  label: i18n.t(
                    'common.userFields.reproductiveDisorderValues.polycysticOvarySyndrome'
                  ),
                  value: 'Polycystic Ovary Syndrome (PCOS)',
                },
                {
                  label: i18n.t(
                    'common.userFields.reproductiveDisorderValues.poorEggQuality'
                  ),
                  value: 'Poor Egg Quality',
                },
                {
                  label: i18n.t(
                    'common.userFields.reproductiveDisorderValues.sexuallyTransmittedDiseases'
                  ),
                  value: 'Sexually Transmitted Diseases (STDs)',
                },
                {
                  label: i18n.t(
                    'common.userFields.reproductiveDisorderValues.tubalFactors'
                  ),
                  value: 'Tubal Factors',
                },
                {
                  label: i18n.t(
                    'common.userFields.reproductiveDisorderValues.other'
                  ),
                  value: 'Other',
                },
              ],
              propertyName: UserInfoFields.known_reproductive_disorder,
              widgetType: TableRowEditWidgetType.SELECT,
            }
          : undefined,
      },
      {
        label: 'common.userFields.averageCycleLength',
        value: userInfo?.length_of_average_monthly_cycle,
        translateLabel: true,
      },
      {
        label: 'common.userFields.lastPeriodStart',
        value: DateUtils.getDateStrFromTimestamp(
          userInfo?.last_first_date_of_period,
          Constants.DATE_DISPLAY_FORMAT
        ),
        translateLabel: true,
      },
      {
        label: 'common.userFields.lastPeriodStartReg',
        value: userInfo?.registration_last_first_date_of_period,
        prettifiedValue: DateUtils.getDateStrFromTimestamp(
          userInfo?.registration_last_first_date_of_period,
          Constants.DATE_DISPLAY_FORMAT
        ),
        translateLabel: true,
        editOptions: {
          widgetType: TableRowEditWidgetType.DATE,
          propertyName: UserInfoFields.registration_last_first_date_of_period,
        },
      },
    ];
  }

  static mapGeneralPatientInfoToTableDataFormat(
    userInfo?: UserInfo
  ): OvTableData {
    return [
      {
        label: 'common.userFields.height',
        value: userInfo?.height_array,
        prettifiedValue: userInfo?.height_array?.length
          ? `${userInfo?.height_array[0]}' ${
              userInfo?.height_array[1]
            }'' | ${ConversionUtils.heightInCm(
              userInfo?.height_array[0],
              userInfo?.height_array[1]
            ).toFixed(2)} ${i18n.t('common.measurements.cm')}`
          : '',
        translateLabel: true,
      },
      {
        label: 'common.userFields.weight',
        value: userInfo?.weight,
        prettifiedValue: isNumber(userInfo?.weight)
          ? `${userInfo?.weight} ${i18n.t('common.measurements.lbs')} | ${
              userInfo?.weight &&
              ConversionUtils.lbsToKg(userInfo?.weight).toFixed(2)
            } ${i18n.t('common.measurements.kg')}`
          : userInfo?.weight,
        translateLabel: true,
      },
      {
        label: 'common.userFields.race',
        value: userInfo?.race,
        translateLabel: true,
      },
      {
        label: 'common.userFields.ethnicity',
        value: userInfo?.ethnicity,
        translateLabel: true,
      },
      {
        label: 'common.userFields.dateOfBirth',
        value: userInfo?.day_of_birth,
        prettifiedValue: DateUtils.getDateStrFromTimestamp(
          userInfo?.day_of_birth,
          Constants.DATE_DISPLAY_FORMAT
        ),
        translateLabel: true,
      },
    ];
  }

  static mapGeneralProviderData(userInfo?: UserInfo): OvTableData {
    return [
      {
        label: 'common.userFields.firstName',
        value: userInfo?.first_name,
        translateLabel: true,
        editOptions: {
          widgetType: TableRowEditWidgetType.TEXT,
          propertyName: UserInfoFields.first_name,
          placeholder: i18n.t('common.userFields.firstName'),
        },
      },
      {
        label: 'common.userFields.lastName',
        value: userInfo?.last_name,
        translateLabel: true,
        editOptions: {
          widgetType: TableRowEditWidgetType.TEXT,
          propertyName: UserInfoFields.last_name,
          placeholder: i18n.t('common.userFields.lastName'),
        },
      },
      {
        label: 'common.userFields.email',
        value: userInfo?.email,
        translateLabel: true,
      },
      {
        label: 'common.userFields.dateOfBirth',
        value: userInfo?.day_of_birth,
        prettifiedValue: DateUtils.getDateStrFromTimestamp(
          userInfo?.day_of_birth,
          Constants.DATE_DISPLAY_FORMAT
        ),
        translateLabel: true,
        editOptions: {
          widgetType: TableRowEditWidgetType.DATE,
          propertyName: UserInfoFields.day_of_birth,
        },
      },
    ];
  }

  static mapProviderInfoToTableDataFormat(
    userInfo?: UserInfo,
    clinicLocationId?: string
  ): OvTableData {
    return [
      {
        label: 'common.userFields.providerData.title',
        value: userInfo?.provider_clinic_locations.find(
          (providerClinicLocation) =>
            providerClinicLocation.id === clinicLocationId
        )?.title,
        translateLabel: true,
        editOptions: {
          widgetType: TableRowEditWidgetType.TEXT,
          propertyName: UserInfoFields.title,
          placeholder: i18n.t('common.userFields.providerData.title'),
        },
      },
      {
        label: 'common.userFields.providerData.phoneNumber',
        value: userInfo?.provider_clinic_locations.find(
          (providerClinicLocation) =>
            providerClinicLocation.id === clinicLocationId
        )?.phone_number,
        translateLabel: true,
        editOptions: {
          widgetType: TableRowEditWidgetType.TEXT,
          propertyName: UserInfoFields.phone_number,
          placeholder: i18n.t('common.userFields.providerData.phoneNumber'),
        },
      },
      {
        label: 'common.userFields.providerData.aboutMe',
        value: userInfo?.provider_clinic_locations.find(
          (providerClinicLocation) =>
            providerClinicLocation.id === clinicLocationId
        )?.about_me,
        translateLabel: true,
        editOptions: {
          widgetType: TableRowEditWidgetType.TEXT_AREA,
          propertyName: UserInfoFields.about_me,
          placeholder: i18n.t('common.userFields.providerData.aboutMe'),
        },
      },
    ];
  }

  static mapUserCycleInfoToTableDataFormat(cycle_info: CycleInfo): OvTableData {
    return [
      {
        label: 'common.userFields.cycleInfo.position',
        value: cycle_info?.position,
        translateLabel: true,
      },
      {
        label: 'common.userFields.cycleInfo.daysUntilScanningStartsForLHPG',
        value: cycle_info?.daysUntilScanningStartsForLHPG,
        translateLabel: true,
      },
      {
        label: 'common.userFields.cycleInfo.daysUntilScanningEndsForLHPG',
        value: cycle_info?.daysUntilScanningEndsForLHPG,
        translateLabel: true,
      },
      {
        label: 'common.userFields.cycleInfo.daysUntilScanningStartsForLRE3G',
        value: cycle_info?.daysUntilScanningStartsForLRE3G,
        translateLabel: true,
      },
      {
        label: 'common.userFields.cycleInfo.daysUntilScanningEndsForLRE3G',
        value: cycle_info?.daysUntilScanningEndsForLRE3G,
        translateLabel: true,
      },
      {
        label: 'common.userFields.cycleInfo.daysUntilFertileWindow',
        value: cycle_info?.daysUntilFertileWindow,
        translateLabel: true,
      },
      {
        label: 'common.userFields.cycleInfo.dateOfCycleStart',
        value: cycle_info?.dateOfCycleStart,
        prettifiedValue: DateUtils.getDateStrFromTimestamp(
          cycle_info?.dateOfCycleStart,
          Constants.DATE_DISPLAY_FORMAT
        ),
        translateLabel: true,
      },
      {
        label: 'common.userFields.cycleInfo.dateOfScanStart',
        value: cycle_info?.dateOfScanStart,
        prettifiedValue: DateUtils.getDateStrFromTimestamp(
          cycle_info?.dateOfScanStart,
          Constants.DATE_DISPLAY_FORMAT
        ),
        translateLabel: true,
      },
      {
        label: 'common.userFields.cycleInfo.dateOfScanEnd',
        value: cycle_info?.dateOfScanEnd,
        prettifiedValue: DateUtils.getDateStrFromTimestamp(
          cycle_info?.dateOfScanEnd,
          Constants.DATE_DISPLAY_FORMAT
        ),
        translateLabel: true,
      },
      {
        label: 'common.userFields.cycleInfo.scanWindowLength',
        value: cycle_info?.scanWindowLength,
        translateLabel: true,
      },
      {
        label: 'common.userFields.cycleInfo.scanWindowStartOffset',
        value: cycle_info?.scanWindowStartOffset,
        translateLabel: true,
      },
      {
        label: 'common.userFields.cycleInfo.dayOfScanning',
        value: cycle_info?.dayOfScanning,
        translateLabel: true,
      },
      {
        label: 'common.userFields.cycleInfo.dayOfCycle',
        value: cycle_info?.dayOfCycle,
        translateLabel: true,
      },
      {
        label: 'common.userFields.cycleInfo.cycleLengthInDays',
        value: cycle_info?.cycleLengthInDays,
        translateLabel: true,
      },
      {
        label: 'common.userFields.cycleInfo.shouldScanLhPg',
        prettifiedValue: cycle_info?.shouldScanLhPg
          ? i18n.t('common.actions.yes')
          : i18n.t('common.actions.no'),
        value: cycle_info?.shouldScanLhPg,
        translateLabel: true,
      },
      {
        label: 'common.userFields.cycleInfo.shouldScanLrE3g',
        prettifiedValue: cycle_info?.shouldScanE3g
          ? i18n.t('common.actions.yes')
          : i18n.t('common.actions.no'),
        value: cycle_info?.shouldScanE3g,
        translateLabel: true,
      },
      {
        label: 'common.userFields.cycleInfo.daysSinceLastPeriodStart',
        value: cycle_info?.daysSinceLastPeriodStart,
        translateLabel: true,
      },
      {
        label: 'common.userFields.cycleInfo.daysInPostOvulation',
        value: cycle_info?.daysInPostOvulation,
        translateLabel: true,
      },
    ];
  }

  static mapUserSpecificBaseLinesToTableDataFormat(
    userSpecificBaselines?: UserSpecificBaselines
  ): OvTableData {
    if (!userSpecificBaselines?.period_cycles) return [];

    const tableData: OvTableData = [];

    for (const periodCycle of userSpecificBaselines.period_cycles) {
      tableData.push({
        label: periodCycle?.cycleBaseLines?.days_since_period_cycle_end
          ? `${periodCycle.start_day} - ${periodCycle.end_day} - (Cycle length: ${periodCycle.cycleBaseLines.cycle_length}, ended ${periodCycle.cycleBaseLines.days_since_period_cycle_end} days ago)`
          : `${periodCycle.start_day} - ${periodCycle.end_day}`,
        nestedRows: [
          {
            label: i18n.t('userDetails.baselines.periodCycle.cycleLength'),
            value: periodCycle.cycleBaseLines?.cycle_length,
            translateLabel: true,
          },
          {
            label: i18n.t('userDetails.baselines.periodCycle.cycleStatus'),
            value: periodCycle.cycleBaseLines?.cycle_status,
            translateLabel: true,
          },
          {
            label: i18n.t(
              'userDetails.baselines.periodCycle.daysBetweenLhAndPgMax'
            ),
            value: periodCycle.cycleBaseLines?.days_between_lh_max_and_pg_max,
            translateLabel: true,
          },
          {
            label: i18n.t(
              'userDetails.baselines.periodCycle.follicularPhaseStartDay'
            ),
            value: periodCycle.cycleBaseLines?.follicular_phase_start_day,
            translateLabel: true,
          },
          {
            label: i18n.t(
              'userDetails.baselines.periodCycle.follicularPhaseEndDay'
            ),
            value: periodCycle.cycleBaseLines?.follicular_phase_end_day,
            translateLabel: true,
          },
          {
            label: i18n.t(
              'userDetails.baselines.periodCycle.lengthOfFollicularPhase'
            ),
            value: periodCycle.cycleBaseLines?.length_of_follicular_phase,
            translateLabel: true,
          },
          {
            label: i18n.t(
              'userDetails.baselines.periodCycle.lutealPhaseStartDay'
            ),
            value: periodCycle.cycleBaseLines?.luteal_phase_start_day,
            translateLabel: true,
          },
          {
            label: i18n.t(
              'userDetails.baselines.periodCycle.lutealPhaseEndDay'
            ),
            value: periodCycle.cycleBaseLines?.luteal_phase_end_day,
            translateLabel: true,
          },
          {
            label: i18n.t(
              'userDetails.baselines.periodCycle.lengthOfLutealPhase'
            ),
            value: periodCycle.cycleBaseLines?.length_of_luteal_phase,
            translateLabel: true,
          },
          {
            label: i18n.t('userDetails.baselines.periodCycle.lhMaxInCycle'),
            value: periodCycle.cycleBaseLines?.lh_max,
            prettifiedValue: periodCycle.cycleBaseLines?.lh_max
              ? `${periodCycle.cycleBaseLines?.lh_max?.value} ${Constants.LH_UNIT_OF_MEASURE} (${periodCycle.cycleBaseLines?.lh_max?.day})`
              : '-',
            translateLabel: true,
          },
          {
            label: i18n.t('userDetails.baselines.periodCycle.pgMaxInCycle'),
            value: periodCycle.cycleBaseLines?.pg_max,
            prettifiedValue: periodCycle.cycleBaseLines?.pg_max?.value
              ? `${periodCycle.cycleBaseLines?.pg_max?.value} ${Constants.PG_UNIT_OF_MEASURE} (${periodCycle.cycleBaseLines?.pg_max?.day})`
              : '-',
            translateLabel: true,
          },
          {
            label: i18n.t('userDetails.baselines.periodCycle.lre3gMaxInCycle'),
            value: periodCycle.cycleBaseLines?.lr_e3g_max,
            prettifiedValue: periodCycle.cycleBaseLines?.lr_e3g_max
              ? `${periodCycle.cycleBaseLines?.lr_e3g_max?.value} ${Constants.E3G_UNIT_OF_MEASURE} (${periodCycle.cycleBaseLines?.lr_e3g_max?.day})`
              : '-',
            translateLabel: true,
          },
          {
            label: i18n.t(
              'userDetails.baselines.periodCycle.numberOfHighLhDays'
            ),
            value: periodCycle.cycleBaseLines?.number_of_high_lh_days,
            translateLabel: true,
          },
        ],
        value: !!userSpecificBaselines?.period_cycles,
        style: {
          backgroundColor: Colours.OV_LIGHT_GRAY,
        },
        showNestedRows: false,
      });
    }

    return tableData;
  }

  static mapAggregateHormoneDataToTableDataFormat(
    userSpecificBaselines?: UserSpecificBaselines
  ): OvTableData {
    if (!userSpecificBaselines?.aggregate_hormone_data) {
      return [];
    }

    const aggregateHormoneData = userSpecificBaselines?.aggregate_hormone_data;

    return [
      {
        label: 'Aggregate hormone data',
        nestedRows: [
          {
            label: i18n.t('userDetails.baselines.aggregateHormoneData.maxLh'),
            value: aggregateHormoneData.max_lh,
            prettifiedValue: aggregateHormoneData.max_lh
              ? `${aggregateHormoneData.max_lh.toFixed(2)} ${
                  Constants.LH_UNIT_OF_MEASURE
                }`
              : '-',
            translateLabel: true,
          },
          {
            label: i18n.t(
              'userDetails.baselines.aggregateHormoneData.averageMaxLh'
            ),
            value: aggregateHormoneData.average_max_lh,
            prettifiedValue: aggregateHormoneData.average_max_lh
              ? `${aggregateHormoneData.average_max_lh.toFixed(2)} ${
                  Constants.LH_UNIT_OF_MEASURE
                }`
              : '-',
            translateLabel: true,
          },
          {
            label: i18n.t(
              'userDetails.baselines.aggregateHormoneData.medianMaxLh'
            ),
            value: aggregateHormoneData.median_max_lh,
            prettifiedValue: aggregateHormoneData.median_max_lh
              ? `${aggregateHormoneData.median_max_lh.toFixed(2)} ${
                  Constants.LH_UNIT_OF_MEASURE
                }`
              : '-',
            translateLabel: true,
          },
          {
            label: i18n.t('userDetails.baselines.aggregateHormoneData.maxPg'),
            value: aggregateHormoneData.max_pg,
            prettifiedValue: aggregateHormoneData.max_pg
              ? `${aggregateHormoneData.max_pg.toFixed(2)} ${
                  Constants.PG_UNIT_OF_MEASURE
                }`
              : '-',
            translateLabel: true,
          },
          {
            label: i18n.t(
              'userDetails.baselines.aggregateHormoneData.averageMaxPg'
            ),
            value: aggregateHormoneData.average_max_pg,
            prettifiedValue: aggregateHormoneData.average_max_pg
              ? `${aggregateHormoneData.average_max_pg.toFixed(2)} ${
                  Constants.PG_UNIT_OF_MEASURE
                }`
              : '-',
            translateLabel: true,
          },
          {
            label: i18n.t(
              'userDetails.baselines.aggregateHormoneData.mediaMaxPg'
            ),
            value: aggregateHormoneData.median_max_pg,
            prettifiedValue: aggregateHormoneData.median_max_pg
              ? `${aggregateHormoneData.median_max_pg.toFixed(2)} ${
                  Constants.PG_UNIT_OF_MEASURE
                }`
              : '-',
            translateLabel: true,
          },
          {
            label: i18n.t(
              'userDetails.baselines.aggregateHormoneData.maxLrE3g'
            ),
            value: aggregateHormoneData.max_lr_e3g,
            prettifiedValue: aggregateHormoneData.max_lr_e3g
              ? `${aggregateHormoneData.max_lr_e3g.toFixed(2)} ${
                  Constants.E3G_UNIT_OF_MEASURE
                }`
              : '-',
            translateLabel: true,
          },
          {
            label: i18n.t(
              'userDetails.baselines.aggregateHormoneData.averageMaxLrE3g'
            ),
            value: aggregateHormoneData.average_max_lr_e3g,
            prettifiedValue: aggregateHormoneData.average_max_lr_e3g
              ? `${aggregateHormoneData.average_max_lr_e3g.toFixed(2)} ${
                  Constants.E3G_UNIT_OF_MEASURE
                }`
              : '-',
            translateLabel: true,
          },
          {
            label: i18n.t(
              'userDetails.baselines.aggregateHormoneData.medianMaxLrE3g'
            ),
            value: aggregateHormoneData.median_max_lr_e3g,
            prettifiedValue: aggregateHormoneData.median_max_lr_e3g
              ? `${aggregateHormoneData.median_max_lr_e3g.toFixed(2)} ${
                  Constants.E3G_UNIT_OF_MEASURE
                }`
              : '-',
            translateLabel: true,
          },
        ],
        value: !!aggregateHormoneData,
        style: {
          backgroundColor: Colours.OV_BORDER_COLOR_V3,
        },
        showNestedRows: false,
      },
    ];
  }
}
