import { useContext, useMemo, useState } from 'react';
import { Head } from '../../common/utils/Head';
import { useTranslation } from 'react-i18next';
import { SubPageModel } from '../../common/model/ui/sub-page.model';
import PageContentTemplate from '../templates/PageContentTemplate';
import GroupsIcon from '@mui/icons-material/Groups';
import SettingsIcon from '@mui/icons-material/Settings';
import QrCodeIcon from '@mui/icons-material/QrCode';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import StoreIcon from '@mui/icons-material/Store';
import ArticleIcon from '@mui/icons-material/Article';
import UserDetails from '../templates/UserDetails';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import OvPageHeader from '../UI/organisms/OvPageHeader';
import AdminClinicList from '../templates/AdminClinicList';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import ClinicDetails from '../templates/ClinicDetails';
import AdminLotList from '../templates/AdminLotList';
import LotDetails from '../templates/LotDetails';
import OvIdleTimer from '../UI/organisms/OvIdleTimer';
import ClinicLocationDetails from '../templates/ClinicLocationDetails';
import { useAppSelector } from '../../redux/hooks';
import { ClinicLocation } from '../../common/model/dto/clinic-location';
import useAuthorized from '../../hooks/use-authorized';
import { Resource } from '../../common/model/type/resource.enum';
import { Role } from '../../common/model/type/role.enum';
import FilterablePatientList from '../templates/FilterablePatientList';
import PatientDetails from '../templates/PatientDetails';
import SelectedClinicInfo from '../templates/SelectedClinicInfo';
import OvPatientOwnResults from '../UI/organisms/OvPatientOwnResults';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import OvProfileUserInfo from '../UI/organisms/OvProfileUserInfo';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import OrderDetails from '../templates/OrderDetails';
import DailyDataDetails from '../templates/DailyDataDetails';
import ScanDetails from '../templates/ScanDetails';
import PatientDailyDataDetails from '../templates/PatientDailyDataDetails';
import AdminScanList from '../templates/AdminScanList';
import Alert from '@mui/material/Alert';
import styled from 'styled-components';
import { AlertTitle } from '@mui/material';
import Variables from '../../design-system/variables';
import Colours from '../../design-system/colours';
import OrderForm from '../templates/OrderForm';
import ClinicLocationScanList from '../templates/ClinicLocationScanList';
import ChecklistIcon from '@mui/icons-material/Checklist';
import AdminScanSequencesList from '../templates/AdminScanSequencesList';
import ScanSequenceDetails from '../templates/ScanSequenceDetails';
import AdminProductList from '../templates/AdminProductList';
import ProductForm from '../templates/ProductForm';
import ProductDetails from '../templates/ProductDetails';
import OvShopDialog from '../UI/molecules/OvShopDialog';
import { UserInfo } from '../../common/model/dto/user-info';
import ArticleForm from '../templates/ArticleForm';
import AdminArticleList from '../templates/AdminArticleList';
import ArticleDetails from '../templates/ArticleDetails';
import Configuration from '../templates/Configuration';
import ProductPriceDetails from '../templates/ProductPriceDetails';
import ProductPriceForm from '../templates/ProductPriceForm';
import AdminEntitlementsList from '../templates/AdminEntitlementsList';
import EntitlementIcon from '@mui/icons-material/Lock';
import { isEmpty } from 'lodash';
import { hasManagerRole, hasViewerRole } from '../../common/utils/auth.helpers';
import NotificationContentsList from '../templates/NotificationContentsList';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationContentDetails from '../templates/NotificationContentDetails';
import AdminUserList from '../templates/AdminUserList';
import AdminOrdersList from '../templates/AdminOrdersList';
import AdminNotificationList from '../templates/AdminNotificationList';
import MarkUnreadChatAltIcon from '@mui/icons-material/MarkUnreadChatAlt';
import NotificationDetails from '../templates/NotificationDetails';
import { NotificationContext } from '../../context/NotificationContext';

const ApplicationBasePage = () => {
  const { t } = useTranslation();
  const entitlements = useAppSelector((state) => state.auth.entitlements);

  const selectedRole = useAppSelector((state) => state.user.selectedRole);
  const currentUser: UserInfo | undefined = useAppSelector(
    (state) => state.user.currentUser
  );
  const selectedClinicLocation: ClinicLocation | undefined = useAppSelector(
    (state) => state.user.selectedClinicLocation
  );
  const selectedClinicRole: string | undefined = useAppSelector(
    (state) => state.user.selectedClinicRole
  );
  const allowedPatientOperations = useAuthorized(
    Resource.ClinicPatient,
    selectedClinicLocation?.id,
    selectedClinicRole
  );
  const allowedClinicLocationOperations = useAuthorized(
    Resource.ClinicLocation,
    selectedClinicLocation?.id,
    selectedClinicRole
  );
  const canReadPatients = allowedPatientOperations.read;
  const canReadClinicLocation = allowedClinicLocationOperations.read;

  const [subPages, setSubPages] = useState<SubPageModel[]>([]);
  const [isShopDialogOpen, setIsShopDialogOpen] = useState<boolean>(false);

  const { pendingNotificationCount } = useContext(NotificationContext);
  const shouldShowPendingNotificationsBadge = pendingNotificationCount > 0;

  const userOperations = useAuthorized(Resource.User);
  const scanOperations = useAuthorized(Resource.Scan);
  const dailyDataOperations = useAuthorized(Resource.DailyData);
  const orderOperations = useAuthorized(Resource.Order);
  const lotOperations = useAuthorized(Resource.Lot);
  const clinicOperations = useAuthorized(Resource.Clinic);
  const productOperations = useAuthorized(Resource.Product);
  const articleOperations = useAuthorized(Resource.Article);
  const scanningPlanOperations = useAuthorized(Resource.ScanningPlan);

  useMemo(() => {
    if (selectedRole === Role.Admin) {
      setSubPages([
        {
          url: 'configuration-settings',
          component: <Configuration />,
          linkLabel: t('app.subPages.menu.configurationSettings'),
          linkIcon: <SettingsIcon />,
          isVisible: true,
        },
        {
          url: 'notifications/:notificationId',
          component: <NotificationDetails />,
          isVisible: true,
        },
        {
          url: 'notifications',
          component: <AdminNotificationList />,
          linkLabel: t('app.subPages.menu.notifications'),
          linkIcon: <NotificationsIcon />,
          isVisible: true,
          shouldAddBadge: shouldShowPendingNotificationsBadge,
          badgeContent: pendingNotificationCount,
        },
        {
          url: 'notification-contents/:notificationContentId',
          component: <NotificationContentDetails />,
          isVisible: true,
        },
        {
          url: 'notification-contents',
          component: <NotificationContentsList />,
          linkLabel: t('app.subPages.menu.notificationContents'),
          linkIcon: <MarkUnreadChatAltIcon />,
          isVisible: true,
        },
        {
          url: 'entitlements',
          component: <AdminEntitlementsList />,
          linkLabel: t('common.entitlements'),
          linkIcon: <EntitlementIcon />,
          isVisible: true,
        },
        {
          url: 'scan-sequences/:scanSequenceId',
          component: <ScanSequenceDetails />,
          isVisible: true,
        },
        {
          url: 'scan-sequences',
          component: <AdminScanSequencesList />,
          linkLabel: t('app.subPages.menu.scanSequences'),
          linkIcon: <ChecklistIcon />,
          isVisible: true,
        },
        {
          url: 'clinics/:clinicId/clinic-info/:clinicLocationId',
          component: <ClinicLocationDetails />,
          isVisible: true,
        },
        {
          url: 'clinics/:clinicId',
          component: <ClinicDetails />,
          isVisible: true,
        },
        {
          url: 'articles/create',
          component: <ArticleForm />,
          isVisible: true,
        },
        {
          url: 'articles/edit/:articleId',
          component: <ArticleForm />,
          isVisible: true,
        },
        {
          url: 'articles/detail/:articleId',
          component: <ArticleDetails />,
          isVisible: true,
        },
        {
          url: 'articles',
          component: <AdminArticleList />,
          linkLabel: t('common.articles'),
          linkIcon: <ArticleIcon />,
          isVisible: true,
        },

        {
          url: 'products/create',
          component: <ProductForm />,
          isVisible: true,
        },
        {
          url: 'products/edit/:productId',
          component: <ProductForm />,
          isVisible: true,
        },
        {
          url: 'products/:productId/prices/create',
          component: <ProductPriceForm />,
          isVisible: true,
        },
        {
          url: 'products/detail/:productId/prices/:productPriceId',
          component: <ProductPriceDetails />,
          isVisible: true,
        },
        {
          url: 'products/:productId/prices/:productPriceId/edit',
          component: <ProductPriceForm />,
          isVisible: true,
        },
        {
          url: 'products/detail/:productId',
          component: <ProductDetails />,
          isVisible: true,
        },
        {
          url: 'products',
          component: <AdminProductList />,
          linkLabel: t('common.products'),
          linkIcon: <StoreIcon />,
          isVisible: true,
        },
        {
          url: 'clinics',
          component: <AdminClinicList />,
          linkLabel: t('app.subPages.menu.clinics'),
          linkIcon: <LocalHospitalIcon />,
          isVisible: true,
        },
        {
          url: 'lots/:lotId',
          component: <LotDetails />,
          isVisible: true,
        },
        {
          url: 'lots',
          component: <AdminLotList />,
          linkLabel: t('app.subPages.menu.lots'),
          linkIcon: <QrCodeIcon />,
          isVisible: true,
        },
        {
          url: 'orders/create',
          component: <OrderForm />,
          isVisible: true,
        },
        {
          url: 'orders/:orderId',
          component: <OrderDetails />,
          isVisible: true,
        },
        {
          url: 'orders',
          component: <AdminOrdersList />,
          linkLabel: t('app.subPages.menu.orders'),
          linkIcon: <ShoppingCartIcon />,
          isVisible: true,
        },
        {
          url: 'daily-data/:dailyDataId/scans/:scanId',
          component: <ScanDetails />,
          isVisible: true,
        },
        {
          url: 'daily-data/:dailyDataId',
          component: <DailyDataDetails />,
          isVisible: true,
        },
        {
          url: 'users/:userId',
          component: <UserDetails />,
          isVisible: true,
        },
        {
          url: 'scan-list/:scanId',
          component: <ScanDetails />,
          isVisible: true,
        },
        {
          url: 'scan-list',
          component: <AdminScanList />,
          linkLabel: t('app.subPages.menu.scans'),
          linkIcon: <DocumentScannerIcon />,
          isVisible: true,
        },
        {
          url: 'users',
          component: <AdminUserList />,
          linkLabel: t('app.subPages.menu.users'),
          linkIcon: <GroupsIcon />,
          isVisible: true,
        },
        {
          url: '',
          redirectUrl: 'users',
          component: <AdminUserList />,
          isVisible: true,
        },
      ]);
    }

    if (
      selectedRole === Role.Regular &&
      selectedClinicLocation &&
      selectedClinicRole
    ) {
      setSubPages([
        {
          url: 'https://buy.stripe.com/eVaeYV5IYfkB6B24gv',
          isExternalPage: true,
          linkIcon: <ShoppingBagIcon />,
          linkLabel: t('app.subPages.menu.shop'),
          isVisible: true,
          shouldOpenDialog: true,
          handleDialogOpen: () => setIsShopDialogOpen(true),
        },
        {
          url: 'profile',
          component: <OvProfileUserInfo />,
          linkLabel: t('app.subPages.menu.profile'),
          linkIcon: <AccountCircleRoundedIcon />,
          isVisible: canReadPatients,
        },
        {
          url: 'scan-list',
          component: <ClinicLocationScanList />,
          linkLabel: t('app.subPages.menu.scans'),
          linkIcon: <DocumentScannerIcon />,
          isVisible: true,
        },
        {
          url: 'clinic-info',
          component: <SelectedClinicInfo />,
          linkLabel: t('app.subPages.menu.clinicSiteInfo'),
          linkIcon: <LocalHospitalIcon />,
          isVisible: canReadClinicLocation,
        },
        {
          url: 'patients/:patientId',
          component: <PatientDetails />,
          isVisible: canReadPatients,
        },
        {
          url: 'results',
          component: <OvPatientOwnResults />,
          linkLabel: t('app.subPages.menu.results'),
          linkIcon: <QueryStatsIcon />,
          isVisible: !canReadPatients,
        },
        {
          url: 'patients',
          component: <FilterablePatientList />,
          linkLabel: t('app.subPages.menu.patients'),
          linkIcon: <GroupsIcon />,
          isVisible: canReadPatients,
        },
        {
          url: 'daily-data/:dailyDataId',
          component: <PatientDailyDataDetails />,
          isVisible: true,
        },
        {
          url: '',
          component: canReadPatients ? (
            <FilterablePatientList />
          ) : (
            <OvPatientOwnResults />
          ),
          redirectUrl: canReadPatients ? 'patients' : 'results',
          isVisible: true,
        },
      ]);
    }

    if (
      (hasManagerRole([selectedRole]) || hasViewerRole([selectedRole])) &&
      ![Role.Admin, Role.Regular].includes(selectedRole)
    ) {
      const subPageConfigs = [
        {
          url: 'scan-sequences/:scanSequenceId',
          component: <ScanSequenceDetails />,
          isVisible: scanningPlanOperations.supervise,
        },
        {
          url: 'scan-sequences',
          component: <AdminScanSequencesList />,
          linkLabel: t('app.subPages.menu.scanSequences'),
          linkIcon: <ChecklistIcon />,
          isVisible: scanningPlanOperations.supervise,
        },
        {
          url: 'clinics/:clinicId/clinic-info/:clinicLocationId',
          component: <ClinicLocationDetails />,
          isVisible: clinicOperations.supervise,
        },
        {
          url: 'clinics/:clinicId',
          component: <ClinicDetails />,
          isVisible: clinicOperations.supervise,
        },
        {
          url: 'articles/create',
          component: <ArticleForm />,
          isVisible: articleOperations.create,
        },
        {
          url: 'articles/edit/:articleId',
          component: <ArticleForm />,
          isVisible: articleOperations.update,
        },
        {
          url: 'articles/detail/:articleId',
          component: <ArticleDetails />,
          isVisible: articleOperations.supervise,
        },
        {
          url: 'articles',
          component: <AdminArticleList />,
          linkLabel: t('common.articles'),
          linkIcon: <ArticleIcon />,
          isVisible: articleOperations.supervise,
        },

        {
          url: 'products/create',
          component: <ProductForm />,
          isVisible: productOperations.create,
        },
        {
          url: 'products/edit/:productId',
          component: <ProductForm />,
          isVisible: productOperations.update,
        },
        {
          url: 'products/:productId/prices/create',
          component: <ProductPriceForm />,
          isVisible: productOperations.update,
        },
        {
          url: 'products/detail/:productId/prices/:productPriceId',
          component: <ProductPriceDetails />,
          isVisible: productOperations.supervise,
        },
        {
          url: 'products/:productId/prices/:productPriceId/edit',
          component: <ProductPriceForm />,
          isVisible: productOperations.update,
        },
        {
          url: 'products/detail/:productId',
          component: <ProductDetails />,
          isVisible: productOperations.supervise,
        },
        {
          url: 'products',
          component: <AdminProductList />,
          linkLabel: t('common.products'),
          linkIcon: <StoreIcon />,
          isVisible: productOperations.supervise,
        },
        {
          url: 'clinics',
          component: <AdminClinicList />,
          linkLabel: t('app.subPages.menu.clinics'),
          linkIcon: <LocalHospitalIcon />,
          isVisible: clinicOperations.supervise,
        },
        {
          url: 'lots/:lotId',
          component: <LotDetails />,
          isVisible: lotOperations.supervise,
        },
        {
          url: 'lots',
          component: <AdminLotList />,
          linkLabel: t('app.subPages.menu.lots'),
          linkIcon: <QrCodeIcon />,
          isVisible: lotOperations.supervise,
        },
        {
          url: 'orders/create',
          component: <OrderForm />,
          isVisible: orderOperations.create,
        },
        {
          url: 'orders/:orderId',
          component: <OrderDetails />,
          isVisible: orderOperations.supervise,
        },
        {
          url: 'orders',
          component: <AdminOrdersList />,
          linkLabel: t('app.subPages.menu.orders'),
          linkIcon: <ShoppingCartIcon />,
          isVisible: orderOperations.supervise,
        },
        {
          url: 'daily-data/:dailyDataId/scans/:scanId',
          component: <ScanDetails />,
          isVisible: scanOperations.read,
        },
        {
          url: 'daily-data/:dailyDataId',
          component: <DailyDataDetails />,
          isVisible: dailyDataOperations.read,
        },
        {
          url: 'users/:userId',
          component: <UserDetails />,
          isVisible: userOperations.supervise,
        },
        {
          url: 'users',
          component: <AdminUserList />,
          linkLabel: t('app.subPages.menu.users'),
          linkIcon: <GroupsIcon />,
          isVisible: userOperations.supervise,
        },
        {
          url: 'scan-list/:scanId',
          component: <ScanDetails />,
          isVisible: scanOperations.supervise,
        },
        {
          url: 'scan-list',
          component: <AdminScanList />,
          linkLabel: t('app.subPages.menu.scans'),
          linkIcon: <DocumentScannerIcon />,
          isVisible: scanOperations.supervise,
        },
      ];

      const redirectUrl = [...subPageConfigs]
        .filter((config) => !isEmpty(config.url))
        .reverse()
        .find((config) => config.isVisible && !config.url.includes(':'))?.url;

      setSubPages([
        ...subPageConfigs,
        {
          url: '',
          redirectUrl,
          isVisible: true,
        },
      ]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    canReadPatients,
    canReadClinicLocation,
    selectedRole,
    entitlements,
    pendingNotificationCount,
    shouldShowPendingNotificationsBadge,
  ]);

  return (
    <>
      <Head title={t('app.pageTitle')} description={t('login.pageDesc')} />
      <OvPageHeader />
      {process.env.REACT_APP_ENV === 'staging' && (
        <StyledAlert severity="warning">
          <AlertTitle>{t('app.staging.alertTitle')}</AlertTitle>
          {t('app.staging.alertDesc')}
        </StyledAlert>
      )}
      <OvIdleTimer />
      <PageContentTemplate subPages={subPages} />
      <OvShopDialog
        isOpen={isShopDialogOpen}
        onClose={() => setIsShopDialogOpen(false)}
        currentUser={currentUser}
        selectedClinicLocation={selectedClinicLocation}
      />
    </>
  );
};

export default ApplicationBasePage;

const StyledAlert = styled(Alert)`
  && {
    position: relative;
    z-index: 1;
    top: 4.25rem;
    margin: 0.25rem 0.5rem 0 0.5rem;
    border-radius: ${Variables.borderRadius.LARGE};
    background-color: ${Colours.OV_YELLOW};
    color: ${Colours.OV_BASE};

    .MuiAlertTitle-root {
      font-weight: bold !important;
    }
  }
`;
