import { LotStatusType } from '../type/lot-status.type';
import { LotType } from '../type/lot.type';
import { HormoneModelFitParameters } from './hormone-model-fit-parameters';
import { CalibrationParameters } from './calibaration-paramteres';

export interface Lot {
  id?: string;
  document_id?: string;
  name?: string;
  type?: LotType;
  ax_array?: number[];
  lr_e3g_array?: number[];
  lh_array?: number[];
  pg_array?: number[];
  lr_e3g_calibration?: CalibrationParameters;
  lr_e3g_model_fit_parameters?: HormoneModelFitParameters;
  lh_calibration?: CalibrationParameters;
  lh_model_fit_parameters?: HormoneModelFitParameters;
  pg_calibration?: CalibrationParameters;
  pg_model_fit_parameters?: HormoneModelFitParameters;
  scan_with_flash?: boolean;
  status?: LotStatusType;
  date_of_manufacture?: Date;
  date_of_expiry?: Date;
  no_of_manufactured_cartridges?: number;
  no_of_scans?: number;
  usage_percentage?: number;
  date_of_first_scan?: Date;
  no_of_error_scans?: number;
  error_percentage?: number;
  comment?: string;
  lot_data_document_url?: string;
  qr_code_image_url?: string;
  created_at?: Date;
  lh_unit?: string;
  pg_unit?: string;
  lr_e3g_unit?: string;
  toolkit_algo_parameters?: ToolkitAlgoParams;
}

export interface ToolkitAlgoParams {
  QR_CROPPED_IMAGE_SCALE?: number;
  QR_IMAGE_RESIZE_WIDTH?: number;
  RIGHT_IMAGE_RATE?: number;
  EXPECTED_WIDTH?: number;
  NUMBER_OF_CHANNELS?: number;
  THRESHOLD_EDGE_IMAGE?: number;
  INITIAL_POS_RATE?: number;
  LEFT_MARGIN_SCALE?: number;
  RIGHT_MARGIN_SCALE_FOR_FIRST_LINE?: number;
  MAX_INT_THRESHOLD_SCALE?: number;
  LOCAL_RANGE_SCALE?: number;
  MODEL_SCALE?: number;
  MODEL_SCALE_TWO_LINE?: number;
  FINETUNE_WIDTH_SCALE?: number;
  NEIGHBOR_HEIGHT_SCALE?: number;
  NEIGHBOR_DISTANCE_SCALE?: number;
  BACKGROUND_SAMPLE_WIDTH?: number;
  SANITY_FIRST_LINE_STRENGTH_MIN?: number;
  MIN_RED_COMPONENT_DIFF?: number;
  AVG_COLORNESS_THRESHOLD?: number;
  MAX_COLORNESS_THRESHOLD?: number;
  MAX_GREEN_STD_THRESHOLD_LINE?: number;
  MAX_GREEN_STD_THRESHOLD_WHOLE_LINE?: number;
}

export const toolkitAlgoParamsDescriptions = [
  {
    key: 'QR_CROPPED_IMAGE_SCALE',
    description:
      'Size of the cropped image based on the size of the QR code. Default: 1.5',
  },
  {
    key: 'QR_IMAGE_RESIZE_WIDTH',
    description:
      'Image width of the resized image in pixels for QR code finding and reading. Default: 600',
  },
  {
    key: 'RIGHT_IMAGE_RATE',
    description: 'The expected rate of imgWidth / imgHeight. Default: 1.5',
  },
  {
    key: 'EXPECTED_WIDTH',
    description:
      'Expected image width in pixels (400px width and 267px height). Default: 400',
  },
  {
    key: 'NUMBER_OF_CHANNELS',
    description: 'Number of channels (RGBA). Default: 4',
  },
  {
    key: 'THRESHOLD_EDGE_IMAGE',
    description:
      'Threshold the edge image to remove weak edges (intensity value). Default: 15',
  },
  {
    key: 'INITIAL_POS_RATE',
    description:
      'Initial position of ellipse model (percent). Default: 0.15 (15%)',
  },
  {
    key: 'LEFT_MARGIN_SCALE',
    description:
      'Do not search anything before the left margin (15% of image width). Default: 0.15 (15% of image width)',
  },
  {
    key: 'RIGHT_MARGIN_SCALE_FOR_FIRST_LINE',
    description:
      'Do not search the first line after the right margin (45% of image width). Default: 0.45 (45% of image width)',
  },
  {
    key: 'MAX_INT_THRESHOLD_SCALE',
    description:
      'Threshold to find max values higher than max value * MAX_INT_THRESHOLD_SCALE (50% of max value). Default: 0.5 (50% of max value)',
  },
  {
    key: 'LOCAL_RANGE_SCALE',
    description:
      'Range of the local neighborhood for searching local max values (5% of image width). Default: 0.05 (5% of image width)',
  },
  {
    key: 'MODEL_SCALE',
    description:
      'Distance between lines for 3 lines detection (20% of the image width). Default: 0.2 (20% of image width)',
  },
  {
    key: 'MODEL_SCALE_TWO_LINE',
    description:
      'Distance between lines for 2 lines detection (30% of the image width). Default: 0.3 (30% of image width)',
  },
  {
    key: 'FINETUNE_WIDTH_SCALE',
    description:
      'Fine-tuning the lines in this range (5% of the image width). Default: 0.05 (5% of image width)',
  },
  {
    key: 'NEIGHBOR_HEIGHT_SCALE',
    description:
      'Sum values in this range vertically (to both directions) (20% of image height). Default: 0.2 (20% of image height)',
  },
  {
    key: 'NEIGHBOR_DISTANCE_SCALE',
    description:
      'Neighbor of a line to compute intensity difference (background) (6% of image width). Default: 0.06 (6% of image width)',
  },
  {
    key: 'BACKGROUND_SAMPLE_WIDTH',
    description:
      'Width-range of the background sample in pixels (1 pixel to both directions: 3 lines). Default: 1',
  },
  {
    key: 'SANITY_FIRST_LINE_STRENGTH_MIN',
    description:
      'Minimum intensity difference from background for sanity check of first line. Default: 10',
  },
  {
    key: 'MIN_RED_COMPONENT_DIFF',
    description:
      'Minimum intensity difference between R and G (R is higher) for red intensity check of the first line. Default: 8',
  },
  {
    key: 'AVG_COLORNESS_THRESHOLD',
    description:
      'Average colorness of pixels; if higher, set warning flag. Default: 30',
  },
  {
    key: 'MAX_COLORNESS_THRESHOLD',
    description: 'Maximum allowed colorness for a neighborhood. Default: 40',
  },
  {
    key: 'MAX_GREEN_STD_THRESHOLD_LINE',
    description:
      'Max GREEN channel standard deviation in lines for uneven line coloring warning check. Default: 7',
  },
  {
    key: 'MAX_GREEN_STD_THRESHOLD_WHOLE_LINE',
    description:
      'Max GREEN channel standard deviation on the whole lines for uneven line coloring warning check. Default: 10',
  },
];
